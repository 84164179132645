import { useEffect, useRef } from "react";
import WordCloud from "wordcloud";
import styled from "styled-components";
import html2canvas from "html2canvas";

const FEELING = [
  "감사",
  "감수성",
  "공감",
  "다정함",
  "명랑",
  "사랑",
  "상상력",
  "세심함",
  "심미안",
  "열정",
  "온화",
  "용기",
  "자신감",
  "책임감",
  "평정심",
  "포용력",
  "호기심",
  "희망",
];

const THINKING = [
  "논리성",
  "도식화",
  "문제파악",
  "박학다식",
  "분석력",
  "신중성",
  "유연성",
  "응용력",
  "이해력",
  "전략적",
  "중립적",
  "창의성",
  "체계성",
  "추리력",
  "탐구력",
  "통찰력",
  "통합력",
  "판단력",
];

const DOING = [
  "글쓰기",
  "끈기",
  "도전",
  "말하기",
  "소통",
  "유머",
  "자제력",
  "적응력",
  "정돈",
  "주목성",
  "준비성",
  "집중력",
  "촉진",
  "추진력",
  "친절",
  "통솔력",
  "학습",
  "협력",
];

const Container = styled.div`
  display: flex;
  width: 240px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: solid 1px #dedede;
  border-radius: 5px;
  position: absolute;
  z-index: -2;
`;
const Wordcloud = ({ data, count, type, setUrl, style }) => {
  const containerRef = useRef();
  const divRef = useRef();
  // console.log(type);
  data.sort((a, b) => b[1] - a[1]);
  let list = [];
  for (let i = 0; i < data.length; i++) {
    list.push([data[i][0], data[i][1]]);
  }
  // let list = [
  //   ["책임", 8],
  //   ["정직", 5],
  //   ["평등", 5],
  //   ["존중", 4],
  //   ["우정", 3],
  //   ["자유", 3],
  //   ["예의", 2],
  //   ["용기", 2],
  //   ["사랑", 2],
  //   ["자부심", 2],
  //   ["성실", 1],
  //   ["신뢰", 1],
  //   ["이해", 1],
  //   ["협력", 1],
  //   ["열정", 1],
  //   ["긍정", 1],
  //   ["인내", 1],
  //   ["관용", 1],
  //   ["감사", 1],
  //   ["평온", 1],
  //   ["규율", 1],
  //   ["평화", 1],
  //   ["시민의식", 1],
  //   ["성장", 1],
  //   ["지혜", 1],
  //   ["진실", 1],
  //   ["정의", 1],
  //   ["창의", 1],
  //   ["배려", 1],
  //   ["겸손", 1],
  //   ["즐거움", 1],
  //   ["성취", 1],
  //   ["몰입", 1],
  //   ["명예", 1],
  //   ["도전", 1],
  //   ["행복", 1],
  // ];
  // list[0][1] = 10;
  // list.forEach((d) => (d[1] = Math.floor(Math.random() * 10) + 1));
  // list.slice(0, 3).forEach((d) => {
  //   d[1] *= 3;
  // });
  // console.log(list);
  // list = list.filter((i) => i[1] > 1);
  // console.log(list);
  useEffect(() => {
    let i = 0;
    WordCloud(divRef.current, {
      list,
      color: (word, weight, fontsize, distance, theta) => {
        if (type === "value") {
          // console.log(word, weight, distance, theta);
        }
        i++;
        // if (weight >= 6) {
        //   return "#FF812C";
        // } else
        // console.log(i);
        if (type === "value") {
          if (i <= 4) {
            return "#FF812C";
          } else if (i <= 10) {
            return "#00C0E0";
          } else if (i <= 18) {
            return "#FFC842";
          } else {
            return "#DAE233";
          }
        } else {
          if (FEELING.includes(word)) {
            return "#FFC842";
          } else if (THINKING.includes(word)) {
            return "#DAE233";
          } else if (DOING.includes(word)) {
            return "#00C0E0";
          } else {
            return "#DAE233";
          }
        }
      },
      fontWeight: (word, weight, fontsize, distance, theta) => {
        if (weight >= 4) {
          return 600;
        } else if (weight >= 2) {
          return 500;
        } else {
          return "normal";
        }
      },
      shape: "square",
      gridSize: 20,
      minSize: 1,
      weightFactor:
        type === "value"
          ? count <= 1
            ? 30
            : count <= 2
            ? 25
            : count <= 4
            ? 14
            : count <= 6
            ? 12
            : count <= 10
            ? 8
            : count <= 15
            ? 5
            : 3
          : count <= 1
          ? 30
          : count <= 3
          ? 25
          : count <= 5
          ? 9
          : count <= 7
          ? 8
          : count <= 9
          ? 6
          : count <= 10
          ? 5
          : 4,
      shrinkToFit: true,
      drawOutOfBound: false,
      origin: [0, 0],
      rotateRatio: 0,
      shuffle: true,
      fontFamily: "PretendardVariable",
    });

    const convert = async () => {
      setTimeout(async () => {
        const element = containerRef.current;
        const canvas = await html2canvas(element);
        data = canvas.toDataURL("image/jpg");
        setUrl(data);
      }, 1500);
    };
    convert();
  }, []);
  return (
    <Container ref={containerRef}>
      <div
        id="canvas"
        style={{
          width: "200px",
          height: "150px",
          //   borderWidth: "1",
          //   borderColor: "black",
          //   borderStyle: "solid",
          padding: "25px",
        }}
        ref={divRef}
      ></div>
    </Container>
  );
};

export default Wordcloud;
