import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { FEEDBACK_TYPE } from "../../constants";

const Modal = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  //   background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const Form = styled.div`
  width: 600px;
  height: 300px;
  padding: 30px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  gap: 20px;
`;

const RadioButton = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
`;

const RadioInput = styled.input`
  margin: 0;
  margin-right: 3px;
`;

const Input = styled.input`
  border: 1px dashed black;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
`;

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #ff812c;
  color: white;
  font-weight: bold;
  width: 100px;
  margin: 0 10px;
`;

const CreateForm = ({ setCsvArray, close, setProjects }) => {
  const [pid, setPid] = useState("");
  const [company, setCompany] = useState("");
  const [projectName, setProjectName] = useState("");
  const [surveyDates, setSurveyDates] = useState([]);
  const [feedbackType, setFeedbackType] = useState("");
  const [file, setFile] = useState();
  const [createProjectLoading, setCreateProjectLoading] = useState(false);

  const fileReader = new FileReader();

  const csvFileToArray = (string) => {
    // console.log(string);
    const projectHeader = string.slice(0, string.indexOf("\n")).split(",");

    // console.log(projectHeader);
    const projectRows = string
      .slice(string.indexOf("\n") + 1, string.indexOf("#no") - 1)
      .split("\n");
    // console.log(projectRows);
    const csvHeader = string
      .slice(string.indexOf("#no"), string.indexOf("\n", string.indexOf("#no")))
      .split(",");
    // console.log(csvHeader);
    const csvRows = string
      .slice(string.indexOf("\n", string.indexOf("#no")) + 1)
      .split("\n");
    // console.log(csvRows);

    const projectArray = projectRows.map((i) => {
      const values = i.split(",");
      const obj = projectHeader.reduce((object, header, index) => {
        object[header.includes("\r") ? header.split("\r")[0] : header] = object[
          header
        ] = values[index].includes("\r")
          ? values[index].split("\r")[0]
          : values[index];

        return object;
      }, {});
      return obj;
    });
    // console.log(projectArray);
    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    // console.log(array);

    return { projects: projectArray, participants: array };
  };

  const handlePidChange = (e) => {
    setPid(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  //   const handlePidChange = (e) => {
  //     setPid(e.target.value);
  //   }

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    if (!feedbackType) {
      alert("피드백 타입을 선택하세요.");

      return;
    }

    if (!file) {
      alert("파일을 업로드하세요.");
      return;
    }

    setCreateProjectLoading(true);
    fileReader.onload = async function (event) {
      const text = event.target.result;
      const { projects, participants } = csvFileToArray(text);

      // console.log(participants);
      // console.log(pid, company, projectName);
      // console.log(new Date(projects[0]["발송일"]).toISOString());
      // console.log(new Date(projects[0]["설문마감일"]).toISOString());
      // save project to db

      const projectResponse = await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/setup`,
        {
          pid: projects[0]["프로젝트번호"],
          company: projects[0]["기업명"],
          projectName: projects[0]["과정명"],
          feedbackType,
          participantsCount: participants.length,
          sendDates: projects.map((p) => {
            if (p["발송시간"].slice(0, 2)[1] === ":") {
              return new Date(
                new Date(p["교육생 입력시작일"]).setHours(
                  p["발송시간"].slice(0, 1),
                  p["발송시간"].slice(2),
                  0
                )
              ).toISOString();
            }
            return new Date(
              new Date(p["교육생 입력시작일"]).setHours(
                p["발송시간"].slice(0, 2),
                p["발송시간"].slice(3),
                0
              )
            ).toISOString();
          }),
          sendEndDates: projects.map((p) =>
            new Date(
              new Date(p["교육생 입력마감일"]).setHours(9, 0, 0)
            ).toISOString()
          ),
          surveyDates: projects.map((p) => ({
            end: new Date(
              new Date(p["구성원 응답마감일"]).setHours(18, 0, 0)
            ).toISOString(),
          })),
          firstRemindDate: projects.map((p) =>
            new Date(
              new Date(p["1차 리마인드"]).setHours(9, 0, 0)
            ).toISOString()
          ),
          secondRemindDate: projects.map((p) =>
            new Date(
              new Date(p["2차 리마인드"]).setHours(9, 0, 0)
            ).toISOString()
          ),
          educationStartDate: projects.map((p) =>
            new Date(new Date(p["교육 시작일"]).setHours(9, 0, 0)).toISOString()
          ),
          participants,
        }
      );

      setProjects((cur) => [...cur, projectResponse.data.project]);
      // console.log(projectResponse);
      // // save participants to db
      // const participantsResponse = await axios.post(
      //   `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/participants`,
      //   {
      //     participants,
      //     company: projects[0]["기업명"],
      //     pid: projects[0]["프로젝트번호"],
      //   }
      // );
      // const announceEmailResponse = await axios.post(
      //   `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/announce`,
      //   {
      //     pid: projects[0]["프로젝트번호"],
      //   }
      // );

      setCreateProjectLoading(false);
      close();
    };

    fileReader.readAsText(file);
  };

  const handleRadioChange = (e) => {
    console.log(e.target.value);
    setFeedbackType(e.target.value);
  };
  return (
    <Modal>
      <Form>
        <RadioButtonContainer>
          <span style={{ fontWeight: "bold" }}>피드백 타입:</span>
          <RadioButton>
            <RadioInput
              type="radio"
              name="type"
              value={FEEDBACK_TYPE.A}
              onChange={handleRadioChange}
            />
            <label htmlFor={FEEDBACK_TYPE.A}>{FEEDBACK_TYPE.A}</label>
          </RadioButton>
          <RadioButton>
            <RadioInput
              type="radio"
              name="type"
              value={FEEDBACK_TYPE.B}
              onChange={handleRadioChange}
            />
            <label htmlFor={FEEDBACK_TYPE.B}>{FEEDBACK_TYPE.B}</label>
          </RadioButton>
        </RadioButtonContainer>
        <Input type="file" accept={".csv"} onChange={handleOnChange} />
        <ButtonContainer>
          <Button onClick={handleCreate}>생성</Button>
          <Button
            onClick={close}
            style={{ background: "#EEEEEE", color: "black" }}
          >
            취소
          </Button>
        </ButtonContainer>
      </Form>
      {createProjectLoading && (
        <Modal style={{ alignItems: "center" }}>
          <span style={{ color: "white", fontSize: "40px" }}>
            프로젝트 생성중....
          </span>
        </Modal>
      )}
    </Modal>
  );
};

export default CreateForm;
