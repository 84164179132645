import React, { useState } from "react";

const UploadPDFModal = ({ show, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      alert("PDF 파일을 선택하세요.");
      return;
    }
    onUpload(selectedFile); // 부모 컴포넌트에서 처리
  };

  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          width: "400px",
          textAlign: "center",
        }}
      >
        <h3>PDF 파일 등록</h3>
        <input type="file" accept=".pdf" onChange={handleFileChange} />
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={handleUpload}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            완료
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "10px 20px",
              marginLeft: "10px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPDFModal;
