import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  height: 100vh;
  // margin-left: 280px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // border: 1px solid black;
  // padding: 50px 0;
`;

const Layout = () => {
  return (
    <Container>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </Container>
  );
};

export default Layout;
