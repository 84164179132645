import React, { useState, useMemo, useEffect } from "react";
import * as XLSX from "xlsx";
import EditModal from "./EditModal";
import FileUploadModal from "./FileUploadModal";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import PaginationControls from "./PaginationControls";

const MWCList = () => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCount, setSelectedCount] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/bloom/mwc/all`,
        {
          method: "GET",
        }
      );
      const result = await response.json();
      console.log(result.data[0]._id);
      setItems(result.data);
    }
    fetchData();
  }, []);

  const formatDateToKST = (date) => {
    const localDate = new Date(date);
    return new Date(localDate.getTime() + 9 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0];
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];
          const rawData = XLSX.utils.sheet_to_json(sheet);

          if (rawData.length === 0) {
            alert("엑셀 파일이 비어 있습니다.");
            return;
          }

          // 날짜 포맷 변환 함수
          const formatDate = (date) => {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate.getTime())) {
              return ""; // 유효하지 않은 날짜 처리
            }
            return parsedDate.toISOString().split("T")[0]; // YYYY-MM-DD 형식
          };

          const newItems = rawData.map((row, index) => ({
            _id: row._id,
            name: row["성명"],
            phone: row["휴대폰번호"],
            email: row["이메일"],
            organization: row["소속"],
            course: row["교육과정명"],
            courseStartDate: formatDate(row["교육시작일"]),
            courseEndDate: formatDate(row["교육종료일"]),
            courseHour: row["교육시간"],
            certificateId: row["수료번호"],
            fts: [row["담당FT_1"], row["담당FT_2"]],
          }));

          // 데이터 유효성 검사
          if (newItems.some((item) => !item.name || !item.course)) {
            alert("필수 필드가 누락되었습니다. 데이터를 확인하세요.");
            return;
          }

          // 서버로 데이터 전송
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_DOMAIN}/bloom/mwc`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newItems),
            }
          );

          const result = await response.json();
          if (result.ok) {
            alert("업로드 성공");
            setItems((prevItems) => [...prevItems, ...newItems]);
          } else {
            alert("업로드 실패: " + result.message);
          }
        } catch (error) {
          console.error("파일 처리 중 오류 발생:", error);
          alert("업로드 중 오류가 발생했습니다.");
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleDelete = async (id) => {
    try {
      // 서버로 삭제 요청
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/bloom/mwc/${id}`,
        {
          method: "DELETE",
        }
      );

      const result = await response.json();

      if (result.ok) {
        // 로컬 상태에서 삭제
        setItems((prevItems) => prevItems.filter((item) => item._id !== id));
        alert("삭제 성공");
      } else {
        alert(`삭제 실패: ${result.message}`);
      }
    } catch (error) {
      console.error("삭제 중 오류 발생:", error);
      alert("삭제 중 오류가 발생했습니다.");
    }
  };

  const handleEdit = (id) => {
    const itemToEdit = items.find((item) => item._id === id);
    setEditModalData(itemToEdit);
  };

  const handleEditSave = async () => {
    try {
      // 서버로 수정된 데이터 전송
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/bloom/mwc/${editModalData._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editModalData),
        }
      );

      const result = await response.json();

      if (result.ok) {
        // 서버에서 응답을 성공적으로 받으면 로컬 상태 업데이트
        setItems((prevItems) =>
          prevItems.map((item) =>
            item._id === editModalData._id ? editModalData : item
          )
        );
        alert("수정 성공");
      } else {
        alert(`수정 실패: ${result.message}`);
      }
    } catch (error) {
      console.error("서버와의 통신 중 오류 발생:", error);
      alert("수정 중 오류가 발생했습니다.");
    } finally {
      // 모달 닫기
      setEditModalData(null);
    }
  };

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * selectedCount;
    const endIndex = startIndex + selectedCount;
    return items.slice(startIndex, endIndex);
  }, [items, currentPage, selectedCount]);

  const totalPages = Math.ceil(items.length / selectedCount);

  return (
    <div className="container" style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          {/* <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              padding: "10px",
              width: "40%",
              border: "1px solid #d9d9d9",
              borderRadius: "5px",
            }}
          />
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#ffc842",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            검색
          </button> */}
          <button
            onClick={() => setShowModal(true)}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4caf50",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            수료자 등록
          </button>
          {/* <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#2196f3",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            목록 다운로드
          </button> */}
          <select
            value={selectedCount}
            onChange={(e) => {
              setSelectedCount(Number(e.target.value));
              setCurrentPage(1);
            }}
            style={{
              padding: "10px",
              border: "1px solid #d9d9d9",
              borderRadius: "5px",
            }}
          >
            <option value={20}>20개</option>
            <option value={40}>40개</option>
            <option value={80}>80개</option>
            <option value={100}>100개</option>
          </select>
        </div>
      </div>

      <FileUploadModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleFileUpload={handleFileUpload}
      />

      <EditModal
        editModalData={editModalData}
        setEditModalData={setEditModalData}
        handleEditSave={handleEditSave}
      />

      <div
        style={{
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TableHeader />
        {paginatedItems.map((item, index) => (
          <TableRow
            key={item._id}
            index={index + 1}
            item={item}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            formatDateToKST={formatDateToKST}
          />
        ))}
      </div>

      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default MWCList;
