import React from "react";

// 필드 이름과 한글 레이블 매핑
const fieldLabels = {
  name: "성명",
  phone: "휴대폰번호",
  email: "이메일",
  organization: "소속",
  course: "교육과정명",
  courseStartDate: "교육시작일",
  courseEndDate: "교육종료일",
  courseHour: "교육시간",
  certificateId: "수료번호",
  ft1: "담당FT_1",
  ft2: "담당FT_2",
};

const EditModal = ({ editModalData, setEditModalData, handleEditSave }) => {
  if (!editModalData) return null;

  const handleChange = (field, value) => {
    // fts 배열 처리
    if (field === "ft1") {
      setEditModalData((prev) => ({
        ...prev,
        fts: [value, prev.fts?.[1] || ""],
      }));
    } else if (field === "ft2") {
      setEditModalData((prev) => ({
        ...prev,
        fts: [prev.fts?.[0] || "", value],
      }));
    } else {
      setEditModalData((prev) => ({ ...prev, [field]: value }));
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          width: "400px",
        }}
      >
        <h3>수료자 수정</h3>
        {Object.keys({ ...editModalData, ft1: "", ft2: "" }).map((key) =>
          key !== "id" && fieldLabels[key] ? (
            <div key={key} style={{ marginBottom: "10px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                {fieldLabels[key]}
              </label>
              <input
                type="text"
                value={
                  key === "ft1"
                    ? editModalData.fts?.[0] || ""
                    : key === "ft2"
                    ? editModalData.fts?.[1] || ""
                    : editModalData[key]
                }
                onChange={(e) => handleChange(key, e.target.value)}
                style={{
                  width: "100%",
                  padding: "5px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
              />
            </div>
          ) : null
        )}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          <button
            onClick={handleEditSave}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            저장
          </button>
          <button
            onClick={() => setEditModalData(null)}
            style={{
              padding: "10px 20px",
              marginLeft: "10px",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
