import styled from "styled-components";

const Container = styled.header`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`;

const Title = styled.h1`
  margin-top: 20px;
  font-size: 32px;
  margin-bottom: 20px;
`;

function HeadSection({ title }) {
  return (
    <Container>
      <Logo src={`${process.env.PUBLIC_URL}/assets/betterthan/logo.png`} />
      <Title>{title}</Title>
    </Container>
  );
}

export default HeadSection;
