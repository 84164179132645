import React from "react";

const PaginationControls = ({ currentPage, totalPages, setCurrentPage }) => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
    <button
      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
      style={{
        padding: "5px 10px",
        margin: "0 5px",
        backgroundColor: "#2196f3",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      이전
    </button>
    <span style={{ padding: "5px 10px" }}>
      {currentPage} / {totalPages}
    </span>
    <button
      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
      style={{
        padding: "5px 10px",
        margin: "0 5px",
        backgroundColor: "#2196f3",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      다음
    </button>
  </div>
);

export default PaginationControls;
