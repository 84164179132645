import {
  BlobProvider,
  Canvas,
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import styled from "styled-components";
import { PieChart } from "./PieChart";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getDay } from "../../utils";
import { useSearchParams } from "react-router-dom";

const Container = styled.div`
  width: 100%;
`;

Font.register({
  family: "Pretendard",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff",
      fontWeight: 100,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff",
      fontWeight: 200,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff",
      fontWeight: 300,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff",
      fontWeight: 800,
    },
  ],
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    zIndex: 2,
    position: "relative",
  },
  page: {
    fontFamily: "Pretendard",
    padding: "40 30",
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  pageTitle: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "150%",
    letterSpacing: "-0.907px",
  },
  pageLogoImg: {
    width: 30,
    height: 30,
  },
  sectionTitle: {
    color: "#FF812C",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.907px",
    marginBottom: 10,
  },
  calloutView: {
    backgroundColor: "#EEEEEE",
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: -0.5,
    padding: "10 20",
  },
  row: {
    flexDirection: "row",
  },
  verticalCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  chartListView: {
    width: "28%",
  },
  barView: {
    backgroundColor: "#E9E9E9",
    height: 8,
    width: 80,
    borderRadius: "50%",
    position: "relative",
  },
  bar: {
    position: "absolute",
    borderRadius: "50%",
    height: 8,
    width: "60%",
    backgroundColor: "#FFB80B",
  },
  bold: {
    fontWeight: 700,
  },
  strengthItem: {
    marginBottom: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  strengthTitle: {
    fontSize: 11,
    fontWeight: 700,
  },
  pageFooter: {
    position: "absolute",
    paddingTop: 15,
    paddingBottom: 15,
    // paddingHorizontal: 30,
    left: 30,
    right: 30,
    bottom: 0,
    borderTop: "1px solid #D9D9D9",
    width: "100%",
    fontFamily: "Pretendard",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerSeperator: {
    height: "20px",
    width: "1px",
    backgroundColor: "#D9D9D9",
    marginHorizontal: 15,
  },
  footerLogo: {
    width: "60px",
    height: "18px",
    paddingLeft: 5,
    // border: "1px solid black",
  },
  footerText: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 400,
    marginBottom: 2,
  },
  footerCopyRight: {
    fontSize: 7,
    fontWeight: 300,
    color: "#666666",
  },
});

const tableStyle = StyleSheet.create({
  table: {
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    fontSize: 10,
    marginBottom: 40,
  },
  row: {},
  cell: {
    paddingLeft: 12,
    paddingVertical: 8,
    backgroundColor: "#F2F2F2",
    width: "13%",
    fontWeight: 600,
  },
  bodyCell: {
    width: "37%",
    paddingLeft: 12,
    paddingVertical: 8,
  },
});

const boxStyle = StyleSheet.create({
  container: {
    border: "1px solid #CCC",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  cell: {
    borderRight: "1px solid #CCC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    paddingVertical: 10,
  },
  title: {
    color: "#666",
    textAlign: "center",
    fontSize: 9,
    fontWeight: 500,
    letterSpacing: "-0.907px",
  },
  icon: {
    width: 9,
    height: 9,
  },
  text1: {
    color: "#000",
    fontSize: 20,
    fontWeight: 600,
    // lineHeight: 1.5,
  },
  text2: {
    color: "#000",
    fontSize: 14,
    fontWeight: 600,
    // lineHeight: 1.5,
  },
});

const circleStyle = StyleSheet.create({
  circle: {
    borderRadius: "50%",
    backgroundColor: "#FF812C",
    width: 60,
    height: 60,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "white",
    fontWeight: 700,
    // textAlign: "center",
  },
});

const coverPage = StyleSheet.create({
  logo: {
    width: 40,
    height: 40,
  },
  title: {
    color: "#FF812C",
    fontSize: 36,
    fontWeight: 700,
    lineHeight: 1.4,
    letterSspacing: "-0.964px",
  },
  text1: {
    color: "#000",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.907px",
  },
  text2: {
    color: "#888",
    fontWeight: 600,
    fontSize: 12,
  },
  text3: {
    color: "#888",
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
  },
});
const endPage = StyleSheet.create({
  cover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Pretendard",
    padding: "40 30",
  },
  view: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: 60,
    height: 55,
    marginBottom: 40,
  },
  text: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: "-0.907px",
  },
});

// const valueWords = [
//   ["책임", 8],
//   ["정직", 5],
//   ["평등", 5],
//   ["존중", 4],
//   ["우정", 3],
//   ["자유", 3],
//   ["예의", 2],
//   ["용기", 2],
//   ["사랑", 2],
//   ["자부심", 2],
//   ["성실", 1],
//   ["신뢰", 1],
//   ["이해", 1],
//   ["협력", 1],
//   ["열정", 1],
//   ["긍정", 1],
//   ["인내", 1],
//   ["관용", 1],
//   ["감사", 1],
//   ["평온", 1],
//   ["규율", 1],
//   ["평화", 1],
//   ["시민의식", 1],
//   ["성장", 1],
//   ["지혜", 1],
//   ["진실", 1],
//   ["정의", 1],
//   ["창의", 1],
//   ["배려", 1],
//   ["겸손", 1],
//   ["즐거움", 1],
//   ["성취", 1],
//   ["몰입", 1],
//   ["명예", 1],
//   ["도전", 1],
//   ["행복", 1],
// ];

const BarChart = ({ value, percentage, color }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 100,
        // border: "1px solid black",
      }}
    >
      <Text
        style={{
          fontSize: 8,
          color: "#888",
          fontWeight: 600,
          marginBottom: 6,
        }}
      >
        {percentage}%
      </Text>
      <View
        style={{
          width: 16,
          borderRadius: 3,
          height: percentage,
          backgroundColor: color,
        }}
      />
      {/* <Text
        style={{
          fontSize: 8,
          color: "#888",
          fontWeight: 600,
          marginBottom: 6,
        }}
      >
        {value}
      </Text> */}
    </View>
  );
};

const ManagerReport = () => {
  const pieRef = useRef(null);
  const [pieImg, setPieImg] = useState();
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [params] = useSearchParams();

  useEffect(() => {
    // setPieImg(pieRef.current.toBase64Image("/image/jpeg", 1));
    (async () => {
      const response = await axios.get(
        `${
          process.env.REACT_APP_SERVER_DOMAIN
        }/betterthan/manager-report?pid=${params.get("pid")}`
      );

      if (!response.data.ok) {
        return;
      }

      setData(response.data.data);
      setIsLoading(false);
    })();
    // var ctx = canvas.getContext("2d");

    // ctx.fillRect(50, 50, 50, 50);
    // const image = new Image();
    // image.src = canvas.toDataURL();
    // console.log(image);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        const canvas = document.getElementById("pieChart");
        setPieImg(canvas.toDataURL());
        setIsReady(true);
      }, 1000);
    }
  }, [isLoading]);
  console.log(isLoading);
  if (isLoading) {
    return <div />;
  }
  console.log("data:", data);
  if (!isLoading && !isReady) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100vh",
            zIndex: 20,
            background: "white",
            position: "fixed",
          }}
        ></div>
        <PieChart
          results={[
            data.feelingWordsPercentage,
            data.thinkingWordsPercentage,
            data.doingWordsPercentage,
          ]}
        />
      </>
    );
  }
  const valueWords = [...data.valueWords];
  valueWords.forEach((item, index) => {
    item[2] = index + 1;
  });
  valueWords.sort(() => (Math.random() > 0.5 ? 1 : -1));
  const valueWordsCount = data.valueWords.reduce(
    (prev, cur) => prev + cur[1],
    0
  );

  const convertColor = (type) => {
    switch (type) {
      case "feeling":
        return "#FFC842";
      case "thinking":
        return "#DAE233";
      case "doing":
        return "#00C0E0";
      default:
        return "#FFC842";
    }
  };
  const now = new Date();

  return (
    <Container>
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page id="cover" size="A4" style={styles.page}>
            <Image
              style={coverPage.logo}
              src={`${process.env.PUBLIC_URL}/assets/betterthan/logo.png`}
            />
            <Image
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: 180,
                height: 180,
              }}
              src={`${process.env.PUBLIC_URL}/assets/betterthan/vector_1.png`}
            />
            <Image
              style={{
                position: "absolute",
                right: 0,
                top: 180,
                width: 180,
                height: 180,
              }}
              src={`${process.env.PUBLIC_URL}/assets/betterthan/vector_2.png`}
            />
            <Image
              style={{
                position: "absolute",
                right: 180,
                top: 280,
                width: 80,
                height: 80,
              }}
              src={`${process.env.PUBLIC_URL}/assets/betterthan/vector_3.png`}
            />
            <Image
              style={{
                position: "absolute",
                right: 70,
                top: 480,
                width: 110,
                height: 110,
              }}
              src={`${process.env.PUBLIC_URL}/assets/betterthan/vector_4.png`}
            />
            <View style={{ marginTop: 370 }}></View>
            <View style={{ ...styles.row }}>
              <Text style={{ ...coverPage.title }}>베러댄피드백</Text>
              <Text style={{ fontSize: 20, color: "#FF812C", fontWeight: 600 }}>
                ™
              </Text>
            </View>
            <Text style={{ ...coverPage.title }}>종합 결과 리포트</Text>
            <Text style={{ ...coverPage.text2 }}>
              Better Than Feedback Report
            </Text>
            <View style={{ marginTop: 50 }}></View>
            <Text style={{ ...coverPage.text1, marginBottom: 8 }}>
              {data.company}
            </Text>
            <View style={{ ...styles.row }}>
              <Text
                style={{ ...coverPage.text1, fontWeight: 700, fontSize: 20 }}
              >
                {data.projectName}
              </Text>
            </View>
            {/* <View style={{ ...styles.row }}>
              <Text
                style={{ ...coverPage.text1, fontWeight: 700, fontSize: 20 }}
              >
                김블룸
              </Text>
              <Text> </Text>
              <Text
                style={{ ...coverPage.text1, fontWeight: 500, fontSize: 20 }}
              >
                담당자님
              </Text>
            </View> */}
            <Text style={{ ...coverPage.text3, marginTop: 8 }}>
              {now.getFullYear()}.{now.getMonth() + 1}.{now.getDate()}(
              {getDay(now.getDate())})
            </Text>
            <View style={{ ...styles.pageFooter }} fixed>
              <View
                style={{
                  ...styles.row,
                  ...styles.spaceBetween,
                  position: "relative",
                  width: "100%",
                }}
              >
                <View>
                  <Text
                    style={{ ...styles.footerText, fontSize: 7, width: "66%" }}
                  >
                    베러댄피드백™{" "}
                    <Text style={{ ...styles.footerCopyRight }}>
                      은 주도적인 성장을 지원하는 긍정적인 피드백 도구로, 함께
                      일하는 동료들이 바라본 구성원의 긍정적인 키워드와 기대하는
                      모습을 담았습니다. 베러댄피드백이 구성원의 성장에 도움이
                      되길 기대합니다.
                    </Text>
                  </Text>
                </View>
                <Image
                  style={{
                    ...styles.footerLogo,
                    width: 66,
                    position: "absolute",
                    right: 1,
                  }}
                  src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                ></Image>
              </View>
              {/* <Text style={styles.footerText} render={({ pageNumber }) => 1} /> */}
            </View>
          </Page>
          <Page id="cover" size="A4" style={styles.page}>
            <View style={styles.headingContainer}>
              <View>
                <Text style={styles.pageTitle}>베러댄피드백</Text>
                <View style={{ marginTop: 2 }}></View>
                <Text style={styles.pageTitle}>전체 개요</Text>
              </View>
              <Image
                style={styles.pageLogoImg}
                src={`${process.env.PUBLIC_URL}/assets/betterthan/manager_report_logo.png`}
              />
            </View>
            <View
              style={{
                ...tableStyle.table,
              }}
            >
              <View
                style={{
                  ...styles.row,
                  ...tableStyle.row,
                  borderBottom: "1px solid #CCC",
                }}
              >
                <Text style={{ ...tableStyle.cell }}>회사명</Text>
                <Text style={{ ...tableStyle.bodyCell }}>{data.company}</Text>
                <Text style={{ ...tableStyle.cell }}>과정명</Text>
                <Text style={{ ...tableStyle.bodyCell }}>
                  {data.projectName}
                </Text>
              </View>
              <View style={{ ...styles.row, ...tableStyle.row }}>
                <Text style={{ ...tableStyle.cell }}>진행 날짜</Text>
                <Text style={{ ...tableStyle.bodyCell }}>
                  {data.surveyDate}
                </Text>
                <Text style={{ ...tableStyle.cell }}>차수</Text>
                <Text style={{ ...tableStyle.bodyCell }}>총 1차수</Text>
              </View>
            </View>
            <View style={styles.calloutView}>
              <Text>
                {data.projectName}의 베러댄피드백 결과를 살펴보면, 총{" "}
                <Text style={styles.bold}>{data.participants}명</Text>의
                교육생이 참여하였고 미 참여자는{" "}
                <Text style={styles.bold}>{data.noFriendsParticipants}명</Text>
                입니다. 교육생이 피드백을 요청한 동료의 수는 총{" "}
                <Text style={styles.bold}>{data.totalRequest}명</Text>이고, 이
                중 피드백을 준 동료는{" "}
                <Text style={styles.bold}>{data.replyRequest}명</Text>(
                {Number((data.replyRequest / data.totalRequest) * 100).toFixed(
                  1
                )}
                % 응답)입니다. 교육생 1명당 피드백 요청수는 평균{" "}
                <Text style={styles.bold}>{data.averageSend}개</Text>,{" "}
                <Text style={styles.bold}>{data.averageReply}개</Text>의
                피드백을 받으며 가장 많이 받은 피드백의 개수는{" "}
                <Text style={styles.bold}>{data.maxReply}개</Text>를 받았고,
                가장 적게 받은 사람은{" "}
                <Text style={styles.bold}>{data.minReply}개</Text>를 받았습니다.
                {"\n\n"}
                {data.projectName} 교육생의 상위 강점 TOP3는{" "}
                <Text style={styles.bold}>
                  {data.topStrength[0][0][0]}, {data.topStrength[1][0][0]},{" "}
                  {data.topStrength[2][0][0]}
                </Text>
                이며, 가치 TOP3는{" "}
                <Text style={styles.bold}>
                  {data.topValue[0][0]}, {data.topValue[1][0]},{" "}
                  {data.topValue[2][0]}
                </Text>
                입니다.{"\n"}베러댄피드백을 통해 구성원이 본인의 강점과 가치를
                이해하면, 자기다운 방법으로 성장할 수 있습니다.
              </Text>
            </View>
            {/* <Text
              style={{
                color: "#888",
                fontSize: 10,
                textAlign: "right",
                marginTop: 16,
              }}
            >
              * 전교육생 : 베러댄피드백을 진행한 모든 기업의 교육생 전체
            </Text> */}
            <View style={{ marginTop: 32 }}>
              <Text style={{ ...styles.sectionTitle }}>인원 및 응답 통계</Text>

              <View style={{ ...boxStyle.container, ...styles.row }}>
                <View style={{ ...boxStyle.cell, gap: 6 }}>
                  <View
                    style={{ ...styles.row, ...styles.verticalCenter, gap: 4 }}
                  >
                    <Image
                      style={{ ...boxStyle.icon }}
                      src={`${process.env.PUBLIC_URL}/assets/betterthan/icon_1.png`}
                    ></Image>
                    <Text style={{ ...boxStyle.title }}>교육생 수</Text>
                  </View>
                  <Text style={{ ...boxStyle.text1 }}>
                    {data.participants}
                    <Text style={{ ...boxStyle.text2 }}>명</Text>
                  </Text>
                </View>
                <View style={{ ...boxStyle.cell, gap: 6 }}>
                  <View
                    style={{ ...styles.row, ...styles.verticalCenter, gap: 4 }}
                  >
                    <Image
                      style={{ ...boxStyle.icon }}
                      src={`${process.env.PUBLIC_URL}/assets/betterthan/icon_2.png`}
                    ></Image>
                    <Text style={{ ...boxStyle.title }}>요청한 동료 수</Text>
                  </View>
                  <Text style={{ ...boxStyle.text1 }}>
                    {data.totalRequest}
                    <Text style={{ ...boxStyle.text2 }}>명</Text>
                  </Text>
                </View>
                <View style={{ ...boxStyle.cell, gap: 6 }}>
                  <View
                    style={{ ...styles.row, ...styles.verticalCenter, gap: 4 }}
                  >
                    <Image
                      style={{ ...boxStyle.icon }}
                      src={`${process.env.PUBLIC_URL}/assets/betterthan/icon_3.png`}
                    ></Image>
                    <Text style={{ ...boxStyle.title }}>응답한 동료 수</Text>
                  </View>
                  <Text style={{ ...boxStyle.text1 }}>
                    {data.replyRequest}
                    <Text style={{ ...boxStyle.text2 }}>명</Text>
                  </Text>
                </View>
                <View style={{ ...boxStyle.cell, gap: 6, borderRight: "none" }}>
                  <View
                    style={{
                      ...styles.row,
                      ...styles.verticalCenter,
                      gap: 4,
                    }}
                  >
                    <Image
                      style={{ ...boxStyle.icon }}
                      src={`${process.env.PUBLIC_URL}/assets/betterthan/icon_4.png`}
                    ></Image>
                    <Text style={{ ...boxStyle.title }}>평균 응답 수</Text>
                  </View>
                  <Text style={{ ...boxStyle.text1 }}>
                    {data.averageReply}
                    <Text style={{ ...boxStyle.text2 }}>명</Text>
                  </Text>
                </View>
              </View>
              <View style={{ gap: 4, marginTop: 20 }}>
                <Text
                  style={{
                    color: "#888",
                    fontSize: 8,
                  }}
                >
                  교육생 수 : 해당 교육에 참여한 교육생의 수
                </Text>
                <Text
                  style={{
                    color: "#888",
                    fontSize: 8,
                  }}
                >
                  요청한 동료 수 : 교육생이 베러댄피드백 설문을 요청한 동료 수
                </Text>
                <Text
                  style={{
                    color: "#888",
                    fontSize: 8,
                  }}
                >
                  응답한 동료 수 : 베러댄피드백 설문에 응답한 동료의 수
                </Text>
                <Text
                  style={{
                    color: "#888",
                    fontSize: 8,
                  }}
                >
                  평균 응답 수 : 교육생이 받은 베러댄피드백 평균 응답 수
                </Text>
              </View>
            </View>
            <View style={{ marginTop: "40" }}>
              <Text style={{ ...styles.sectionTitle }}>강점 및 가치 통계</Text>
              <View
                style={{
                  width: "100%",
                  height: 1,
                  borderBottom: "1px solid #ccc",
                  marginBottom: "16",
                }}
              />
              <View
                style={{
                  ...styles.row,
                  gap: "50px",
                  // justifyContent: "space-evenly",
                  // border: "1px solid black",
                }}
              >
                {/* <View>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#333",
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    강점 분포 그래프
                  </Text>
                  <View>
                    <Image src={pieImg} style={{ width: 100, height: 100 }} />
                  </View>
                  <View
                    style={{
                      ...styles.row,
                      justifyContent: "center",
                      marginTop: 12,
                      gap: 6,
                    }}
                  >
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FFC842",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>감성</Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#DAE233",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>이성</Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#00C0E0",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>행동</Text>
                    </View>
                  </View>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#333",
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    타사 강점 그래프
                  </Text>
                  <View>
                    <Image src={pieImg} style={{ width: 100, height: 100 }} />
                  </View>
                  <View
                    style={{
                      ...styles.row,
                      justifyContent: "center",
                      marginTop: 12,
                      gap: 6,
                    }}
                  >
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FFC842",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>감성</Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#DAE233",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>이성</Text>
                    </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.verticalCenter,
                        gap: 2,
                      }}
                    >
                      <View
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#00C0E0",
                        }}
                      />
                      <Text style={{ fontSize: 8, color: "#666" }}>행동</Text>
                    </View>
                  </View>
                </View> */}
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#333",
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    강점 TOP3
                  </Text>
                  <View
                    style={{
                      ...styles.row,
                      gap: 15,
                      width: 100,
                      borderBottom: "1px solid #ccc",
                      justifyContent: "center",
                    }}
                  >
                    <BarChart
                      value={data.topStrength[0][0][0]}
                      percentage={Number(
                        (data.topStrength[0][0][1] / 100) * 100
                      ).toFixed(1)}
                      color={convertColor(data.topStrength[0][1])}
                    />
                    <BarChart
                      value={data.topStrength[1][0][0]}
                      percentage={Number(
                        (data.topStrength[1][0][1] / 100) * 100
                      ).toFixed(1)}
                      color={convertColor(data.topStrength[1][1])}
                    />
                    <BarChart
                      value={data.topStrength[2][0][0]}
                      percentage={Number(
                        (data.topStrength[2][0][1] / 100) * 100
                      ).toFixed(1)}
                      color={convertColor(data.topStrength[2][1])}
                    />
                  </View>
                  <View
                    style={{
                      width: 100,
                      marginTop: 12,
                      justifyContent: "space-between",
                      ...styles.row,
                    }}
                  >
                    {data.topStrength.map((item, index) => (
                      <View
                        key={index}
                        style={{ ...styles.row, fontSize: 9, color: "#333" }}
                      >
                        <Text
                          style={{
                            fontWeight: 600,
                            width: 30,
                            textAlign: "center",
                          }}
                        >
                          {item[0][0]}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#333",
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    가치 TOP3
                  </Text>
                  <View
                    style={{
                      ...styles.row,
                      gap: 15,
                      width: 100,
                      borderBottom: "1px solid #ccc",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {data.topValue.map((item, index) => (
                      <BarChart
                        key={index}
                        value={item[0]}
                        percentage={Number((item[1] / 100) * 100).toFixed(1)}
                        color={"#FF812C"}
                      />
                    ))}
                  </View>
                  <View
                    style={{
                      width: 100,
                      marginTop: 12,
                      justifyContent: "space-between",
                      ...styles.row,
                    }}
                  >
                    {data.topValue.map((item, index) => (
                      <View
                        key={index}
                        style={{ ...styles.row, fontSize: 9, color: "#333" }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            width: 30,
                          }}
                        >
                          {item[0]}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.pageFooter} fixed>
              <View style={styles.row}>
                <Image
                  style={styles.footerLogo}
                  src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                ></Image>
                <View style={styles.footerSeperator}></View>
                <View>
                  <Text style={styles.footerText}>베러댄피드백™</Text>
                  <Text style={styles.footerCopyRight}>
                    Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                    All rights reserved.
                  </Text>
                </View>
              </View>
              <Text style={styles.footerText} render={({ pageNumber }) => 2} />
            </View>
          </Page>
          <Page id="cover" size="A4" style={styles.page}>
            <View style={styles.headingContainer}>
              <Text style={styles.pageTitle}>강점 키워드별 응답 결과</Text>
              <Image
                style={styles.pageLogoImg}
                src={`${process.env.PUBLIC_URL}/assets/betterthan/manager_report_logo.png`}
              />
            </View>
            <View style={styles.calloutView}>
              <Text>
                강점 단어는 감성, 이성, 행동 3개 영역으로 총 54개의 키워드로
                구성되어 있습니다. 동료들이 바라보는 교육생들의 강점은 감성
                영역이 {data.feelingWordsPercentage}%로 가장 높았고, 이성 영역이{" "}
                {data.thinkingWordsPercentage}%, 행동 영역이{" "}
                {data.doingWordsPercentage}%를 차지했습니다. (베러댄피드백 전체
                참여자의 영역별 분포: 감성 영역 {data.allFeelingWordsPercentage}
                %, 행동 영역 {data.allDoingWordsPercentage}%, 이성 영역{" "}
                {data.allThinkingWordsPercentage}%)
                {"\n"}강점 키워드 피드백 결과를 인정 점수*로 환산한 감성 영역
                TOP3는{" "}
                <Text style={styles.bold}>{data.feelingSortable[0][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.feelingSortable[1][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.feelingSortable[2][0]}</Text>
                이고, 이성 영역 TOP3는{" "}
                <Text style={styles.bold}>
                  {data.thinkingSortable[0][0]}
                </Text>,{" "}
                <Text style={styles.bold}>{data.thinkingSortable[1][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.thinkingSortable[2][0]}</Text>
                이고, 행동 영역 TOP3는{" "}
                <Text style={styles.bold}>
                  {data.doingSortable[0][0]}
                </Text>,{" "}
                <Text style={styles.bold}>{data.doingSortable[1][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.doingSortable[2][0]}</Text>
                입니다.{"\n"}강점을 활용하면 에너지가 높아지고 더 나은 성과를
                도출할 수 있습니다. 동료들이 발견해준 본인의 강점을 앞으로
                업무에 적용할 수 있도록 독려해 주세요.
              </Text>
            </View>
            <Text
              style={{
                fontSize: 9,
                color: "#666",
                marginTop: 8,
                lineHeight: 1.6,
              }}
            >
              * 강점 인정 점수란 교육생들이 동료들에게 베러댄피드백 설문을 통해
              수집된 강점을 집계해 점수화한 것입니다. 교육생들이 피드백을 요청한
              동료의 수가 상이하여 개별 강점 집계를 비율로 환산해서
              재계산합니다. 피드백을 요청 동료 전원이 동일한 강점을 투표했을
              경우 1점이 되고, 절반이 동일한 강점을 투표할 경우 0.5점이 됩니다.
              교육생 전체 점수는 개인 점수의 평균을 100점으로 환산했습니다.
            </Text>
            <View style={{ marginTop: 20 }}>
              <View style={{ ...styles.row, ...styles.spaceBetween }}>
                <View style={styles.chartListView}>
                  <View
                    style={{
                      ...styles.row,
                      ...styles.spaceBetween,
                      ...styles.verticalCenter,
                      ...styles.strengthTitle,
                      paddingBottom: 8,
                      marginBottom: 12,
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text>감성 ({data.feelingWordsPercentage}%)</Text>
                    {/* <Text style={{ fontSize: 10 }}>
                      총 {data.feelingWordsCount}개
                    </Text> */}
                  </View>
                  {data.feelingSortable.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        ...styles.strengthItem,
                        width: "100%",
                      }}
                    >
                      <Text style={{ fontSize: 11, fontWeight: 500 }}>
                        {item[0]}
                      </Text>
                      <View
                        style={{
                          ...styles.row,
                          ...styles.verticalCenter,
                          width: 100,
                        }}
                      >
                        <View style={{ ...styles.barView }}>
                          <View
                            style={{
                              ...styles.bar,
                              width: `${Number(item[1]).toFixed(0) * 0.8}px`,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: 500,
                            color: "#888",
                            marginLeft: 8,
                          }}
                        >
                          {item[1]}점
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View style={styles.chartListView}>
                  <View
                    style={{
                      ...styles.row,
                      ...styles.spaceBetween,
                      ...styles.verticalCenter,
                      ...styles.strengthTitle,
                      paddingBottom: 8,
                      marginBottom: 12,
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text>이성 ({data.thinkingWordsPercentage}%)</Text>
                    {/* <Text style={{ fontSize: 10 }}>
                      총 {data.thinkingWordsCount}개
                    </Text> */}
                  </View>
                  {data.thinkingSortable.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        ...styles.strengthItem,
                        width: "100%",
                      }}
                    >
                      <Text style={{ fontSize: 11, fontWeight: 500 }}>
                        {item[0]}
                      </Text>
                      <View
                        style={{
                          ...styles.row,
                          ...styles.verticalCenter,
                          width: 100,
                        }}
                      >
                        <View style={{ ...styles.barView }}>
                          <View
                            style={{
                              ...styles.bar,
                              backgroundColor: "#CED714",
                              width: `${Number(item[1]).toFixed(0) * 0.8}px`,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: 500,
                            color: "#888",
                            marginLeft: 8,
                          }}
                        >
                          {item[1]}점
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                <View style={styles.chartListView}>
                  <View
                    style={{
                      ...styles.row,
                      ...styles.spaceBetween,
                      ...styles.verticalCenter,
                      ...styles.strengthTitle,
                      paddingBottom: 8,
                      marginBottom: 12,
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text>행동 ({data.doingWordsPercentage}%)</Text>
                    {/* <Text style={{ fontSize: 10 }}>
                      총 {data.doingWordsCount}개
                    </Text> */}
                  </View>
                  {data.doingSortable.map((item, index) => (
                    <View
                      key={index}
                      style={{
                        ...styles.strengthItem,
                        width: "100%",
                      }}
                    >
                      <Text style={{ fontSize: 11, fontWeight: 500 }}>
                        {item[0]}
                      </Text>
                      <View
                        style={{
                          ...styles.row,
                          ...styles.verticalCenter,
                          width: 100,
                        }}
                      >
                        <View style={{ ...styles.barView }}>
                          <View
                            style={{
                              ...styles.bar,
                              backgroundColor: "#00C0E0",
                              width: `${Number(item[1] * 0.8).toFixed(0)}px`,
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            fontSize: 8,
                            fontWeight: 500,
                            color: "#888",
                            marginLeft: 8,
                          }}
                        >
                          {item[1]}점
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.pageFooter} fixed>
              <View style={styles.row}>
                <Image
                  style={styles.footerLogo}
                  src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                ></Image>
                <View style={styles.footerSeperator}></View>
                <View>
                  <Text style={styles.footerText}>베러댄피드백™</Text>
                  <Text style={styles.footerCopyRight}>
                    Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                    All rights reserved.
                  </Text>
                </View>
              </View>
              <Text style={styles.footerText} render={({ pageNumber }) => 3} />
            </View>
          </Page>
          <Page id="cover" size="A4" style={styles.page}>
            <View style={styles.headingContainer}>
              <Text style={styles.pageTitle}>가치 키워드별 응답 결과</Text>
              <Image
                style={styles.pageLogoImg}
                src={`${process.env.PUBLIC_URL}/assets/betterthan/manager_report_logo.png`}
              />
            </View>
            <View style={styles.calloutView}>
              <Text>
                가치 단어는 일을 할 때 중요한 기준이 될 수 있는 단어 54개로
                구성되어 있습니다. 가치 키워드 피드백 결과를 인정 점수*로 환산한
                가치 TOP3는{" "}
                <Text style={styles.bold}>{data.valueWords[0][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.valueWords[1][0]}</Text>,{" "}
                <Text style={styles.bold}>{data.valueWords[2][0]}</Text>입니다.
                {"\n"}
                가치란 의사결정의 중요한 기준이 됩니다. 우리 조직 구성원들이
                중요하게 생각하는 가치를 이해하고, 다른 가치를 가진 동료의
                생각도 존중할 때 원활하게 협업할 수 있습니다.
              </Text>
            </View>
            <Text
              style={{
                fontSize: 9,
                color: "#666",
                marginTop: 8,
                lineHeight: 1.6,
              }}
            >
              * 가치 인정 점수란 교육생들이 동료들에게 베러댄피드백 설문을 통해
              수집된 가치를 집계해 점수화한 것입니다. 교육생들이 피드백을 요청한
              동료의 수가 상이하여 개별 가치 집계를 비율로 환산해서
              재계산합니다. 피드백을 요청한 동료 전원이 동일한 가치를 투표했을
              경우 1점이 되고, 절반이 동일한 가치를 투표할 경우 0.5점이 됩니다.
              도형의 색이 진하고 크기가 클수록 가치 인정 점수가 높습니다.
            </Text>
            {/* <Text
              style={{
                color: "#888",
                fontSize: 10,
                textAlign: "right",
                marginTop: 20,
              }}
            >
              * 색이 진하고 크기가 클수록 더 많이 응답
            </Text> */}
            <View
              style={{
                position: "relative",
                marginTop: 30,
                width: "100%",
                height: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {Array(5)
                .fill(0)
                .map((item, id) => (
                  <View
                    key={id}
                    style={{
                      position: "relative",
                      width: "100%",
                      height: 90,
                      zIndex: 1,
                      // border: "1px solid black",
                    }}
                  >
                    {valueWords
                      .slice(7 * id, 7 * (id + 1))
                      .map((item, index) => {
                        {
                          /* console.log(item[0], 60 - item[2]); */
                        }
                        console.log(item);
                        const occurence = item[1] / valueWordsCount;
                        let ratio = 0;
                        if (item[2] < 3) {
                          ratio = 1;
                        } else if (item[2] < 6) {
                          ratio = 0.8;
                        } else if (item[2] < 10) {
                          ratio = 0.6;
                        } else if (item[2] < 18) {
                          ratio = 0.5;
                        } else if (item[2] < 24) {
                          ratio = 0.4;
                        } else if (item[2] < 30) {
                          ratio = 0.3;
                        } else if (item[2] < 35) {
                          ratio = 0.2;
                        }

                        // console.log(
                        //   `row: ${id + 1}, column: ${
                        //     index + 1
                        //   }, top:${randomTop}, left:${randomLeft}`
                        // );
                        const MIN_RADIUS = 35;
                        const MAX_RADIUS = 60;
                        const radius = Math.floor(
                          MAX_RADIUS * ratio + MIN_RADIUS
                        );
                        // console.log(radius, 1 + item[1] / valueWordsCount);
                        const randomTop = Math.floor(Math.random() * 60);
                        const randomLeft = 5 + index * 70;
                        let randomOpacityIndex = 0;
                        let opacity = 0;
                        if (item[2] < 6) {
                          opacity = 1;
                        } else if (item[2] < 15) {
                          opacity = 0.8;
                        } else if (item[2] < 25) {
                          opacity = 0.5;
                        } else {
                          opacity = 0.3;
                        }
                        // const opacity = [0.5, 0.8, 1];
                        return (
                          <View
                            key={index}
                            style={{
                              ...circleStyle.circle,
                              top: randomTop,
                              left: randomLeft,
                              width: radius,
                              height: radius,
                              backgroundColor:
                                item[1] === 0
                                  ? `rgba(238, 238, 238, 0.5)`
                                  : `rgba(255, 129, 44, ${opacity})`,
                              zIndex: 60 - item[2],

                              // opacity: opacity[randomOpacityIndex],
                            }}
                          >
                            <Text
                              style={{
                                ...circleStyle.text,
                                color: item[1] === 0 ? "#aaa" : "#fff",
                                position: "relative",
                                fontSize: Math.floor((28 * radius) / 100),
                                zIndex: 60 - item[2],
                              }}
                            >
                              {item[0]}
                            </Text>
                          </View>
                        );
                      })}
                  </View>
                ))}
            </View>
            <View style={styles.pageFooter} fixed>
              <View style={styles.row}>
                <Image
                  style={styles.footerLogo}
                  src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                ></Image>
                <View style={styles.footerSeperator}></View>
                <View>
                  <Text style={styles.footerText}>베러댄피드백™</Text>
                  <Text style={styles.footerCopyRight}>
                    Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                    All rights reserved.
                  </Text>
                </View>
              </View>
              <Text style={styles.footerText} render={({ pageNumber }) => 4} />
            </View>
          </Page>
          <Page id="cover" size="A4" style={endPage.cover}>
            <View style={endPage.view}>
              <Image
                style={endPage.logo}
                src={`${process.env.PUBLIC_URL}/assets/betterthan/end_logo.png`}
              />
              <Text style={endPage.text}>
                직장생활의 경험을 긍정적으로 바꿉니다.
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Container>
  );
};

export default ManagerReport;
