import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  Canvas,
  PDFDownloadLink,
  BlobProvider,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import styled from "styled-components";
import { useRef } from "react";
import Wordcloud from "./Wordcloud";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const ORANGE = "#FF812C";
const BOX_COLOR = "#F5F5F5";

Font.register({
  family: "Pretendard",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff",
      fontWeight: 100,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff",
      fontWeight: 200,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff",
      fontWeight: 300,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff",
      fontWeight: 800,
    },
  ],
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
    zIndex: 2,
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",

    paddingHorizontal: "30px",
    paddingTop: "40px",
    fontFamily: "Pretendard",
    position: "relative",
    paddingBottom: "100px",
  },
  pageTop: {
    height: "5px",
    width: "100%",
    backgroundColor: ORANGE,
    marginBottom: 39,
  },
  pageFooter: {
    position: "absolute",
    paddingTop: 15,
    paddingBottom: 15,
    // paddingHorizontal: 30,
    left: 30,
    right: 30,
    bottom: 0,
    borderTop: "2px solid #D9D9D9",
    width: "100%",
    fontFamily: "Pretendard",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerSeperator: {
    height: "20px",
    width: "1px",
    backgroundColor: "#D9D9D9",
    marginHorizontal: 15,
  },
  footerText: {
    color: "#666666",
    fontSize: 9,
    fontWeight: 400,
    marginBottom: 2,
  },
  footerCopyRight: {
    fontSize: 7,
    fontWeight: 300,
    color: "#666666",
  },
  coverPage: {
    paddingRight: 45,
    color: "white",
    // position: "relative",
  },
  cover: {
    backgroundColor: ORANGE,
    borderBottomRightRadius: "100px",
  },
  coverFooter: {
    flexDirection: "row",
    justifyContent: "space-between",
    // marginTop: 30,
    paddingTop: 28,
    paddingLeft: 28,
    paddingRight: 20,
    fontWeight: 300,
    color: "#666666",
    // border: "1px solid black",
  },
  coverFooterText: {
    fontFamily: "Pretendard",
    fontSize: "8px",
    width: "400",

    // border: "1px solid black",
  },
  coverFooterLogo: {
    width: "68px",
    height: "20px",
    // paddingLeft: 5,
    // border: "1px solid black",
  },
  footerLogo: {
    width: "60px",
    height: "18px",
    paddingLeft: 5,
    // border: "1px solid black",
  },
  coverContainer: {
    position: "absolute",
    top: "40",
    left: "40",
    fontFamily: "Pretendard",
    // fontSize: "60px",
    // lineHeight: "72px",
  },
  coverTitle: {
    fontWeight: 700,
    fontSize: 40,
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: 10,
  },
  line: {
    height: 5,
    backgroundColor: "white",
    width: 32,
    marginBottom: 27,
    marginTop: 88,
  },
  projectName: {
    fontSize: 20,
    letterSpacing: "-0.5px",
    fontWeight: 500,
    marginBottom: 10,
  },
  date: {
    fontSize: 18,
    fontWeight: 500,
  },
  name: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: "-0.5px",
    marginTop: 300,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  korean: {
    fontFamily: "Pretendard",
    // fontWeight: 200,
  },
  header1: {
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 24,
  },
  tagContainer: {
    padding: "20px",
    backgroundColor: BOX_COLOR,
    borderRadius: 5,
    color: "#010101",
    flexDirection: "row",
  },
  tagText: {
    lineHeight: "1.8px",
    fontWeight: 400,
    letterSpacing: "-0.5px",
    fontSize: 15,
  },
  tagEndText: {
    textAlign: "right",
    fontSize: 18,
    fontWeight: 500,
    marginTop: 20,
  },
  divider: {
    marginVertical: "48px",
    height: "1px",
    backgroundColor: "#d9d9d9",
  },
  cloudContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    rowGap: 33,
  },
  cloudItem: {
    flexDirection: "column",
  },
  cloudHeader: {
    paddingVertical: "10px",
    paddingLeft: 20,
    backgroundColor: ORANGE,
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    width: "230px",
    marginBottom: 10,
    borderRadius: 5,
  },
  cloudImage: {
    width: "230px",
    // border: "5px solid red",
    borderRadius: 5,
  },
  row: {
    flexDirection: "row",
  },
  // page 3
  strengthContainer: {
    color: "#010101",
    marginBottom: 15,
  },
  strenghtHeading: {
    fontSize: 14,
    letterSpacing: "-0.5px",
  },
  strengthWordsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    fontWeight: 600,
    fontSize: 12,
    paddingVertical: 13,
    paddingHorizontal: 26,
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    marginVertical: 10,
    color: "#010101",
  },
  strengthWordHighLight: {
    color: ORANGE,
    marginRight: 4,
    marginBottom: 6,
  },
  strengthWordBold: {
    marginRight: 4,
    marginBottom: 6,
  },
  strengthWordLight: {
    fontWeight: 400,
    marginRight: 4,
    marginBottom: 6,
  },
  strengthGuideText: {
    fontWeight: 400,
    color: "#333333",
    letterSpacing: "-0.5px",
    fontSize: 10,
    textAlign: "right",

    right: 30,
    bottom: 80,
    position: "absolute",
  },
  commentContainer: {},
  commentHeader: {
    color: "#010101",
    fontSize: 16,
    fontWeight: 500,
  },
  commentDivider: {
    height: 1,
    backgroundColor: "#a7a7a7",
    width: "100%",
    marginTop: 25,
    marginBottom: 16,
  },
  commentText: {
    fontSize: 15,
    color: "#222222",
    borderBottom: "1px solid #d9d9d9",
    paddingBottom: 14,
    marginBottom: 14,
  },
});

const Container = styled.div``;
const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: white;
  width: 100%;
  height: 500px;
`;

// Create Document Component
const ReactPdf = ({
  name,
  id,
  pid,
  projectName,
  projectDate,
  downloadDone,
}) => {
  console.log("ReactPdf Called!", id);
  const canvasRef = React.createRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [strengthUrl, setStrengthUrl] = useState("");
  const [url, setUrl] = useState("");
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const pdfRef = useRef();
  const uploadPdf = async (blob, filename) => {
    pdfRef.current.style = "display: none";
    const formData = new FormData();
    formData.append("id", id);
    formData.append("pid", pid.split("-")[0]);
    formData.append("file", blob, filename);

    await axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/pdf`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // pdfRef.current.style = "display: none";
    downloadDone();
  };

  useEffect(() => {
    console.log(url);
    // setIsLoaded(true);
  }, [url, strengthUrl]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan?name=${name}&pid=${pid}&id=${id}`
      );

      setReportData([
        response.data.hashtags,
        response.data.valuesWords,
        response.data.feelingWords,
        response.data.thinkingWords,
        response.data.doingWords,
        response.data.appreciateComments,
        response.data.expectComments,
      ]);

      setCount(response.data.count);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  console.log("show canvas");
  if (isLoading) {
    return null;
  }

  return (
    <Container ref={pdfRef}>
      <Cover />
      <Wordcloud
        data={reportData[1]}
        count={count}
        type="value"
        setUrl={setUrl}
        setIsLoaded={setIsLoaded}
      />
      <Wordcloud
        data={[...reportData[2], ...reportData[3], ...reportData[4]]}
        count={count}
        type="strong"
        setUrl={setStrengthUrl}
      />
      {/* <PDFViewer style={styles.viewer}> */}
      <BlobProvider
        document={
          <Document>
            <Page id="cover" size="A4" style={{ ...styles.coverPage }}>
              <View style={styles.cover}>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/front-cover.png`}
                ></Image>
                <View style={styles.coverContainer}>
                  <View style={styles.row}>
                    <Text style={styles.coverTitle}>
                      베러댄피드백{"\n"}리포트
                    </Text>
                    <Text style={{ fontSize: 28, fontWeight: 600 }}>™</Text>
                  </View>
                  <Text style={styles.subTitle}>
                    Better Than Feedback Report
                  </Text>
                  <View style={styles.line}></View>
                  <Text style={styles.projectName}>{projectName}</Text>
                  <Text style={styles.date}>{`${new Date(
                    projectDate
                  ).getFullYear()}. ${
                    new Date(projectDate).getMonth() + 1
                  }. ${new Date(projectDate).getDate()}`}</Text>
                  <Text style={styles.name}>
                    {name} <Text style={{ fontSize: 24 }}>님</Text>
                  </Text>
                </View>
              </View>
              <View style={styles.coverFooter}>
                <Text style={styles.coverFooterText}>
                  <Text style={{ fontWeight: 500 }}>베러댄피드백™</Text>은
                  주도적인 성장을 지원하는 긍정적인 피드백 도구로, 함께 일하는
                  동료들이 바라본 {name} 님의 긍정적인 키워드와 기대하는 모습을
                  담았습니다. 베러댄피드백이 {name} 님의 성장에 도움이 되길
                  기대합니다.{" "}
                </Text>
                <Image
                  style={styles.coverFooterLogo}
                  src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                ></Image>
              </View>
            </Page>
            <Page size="A4" style={styles.page}>
              <View style={styles.pageTop}></View>
              <View style={{ paddingHorizontal: 20 }}>
                <Text style={styles.header1}>
                  함께 일하는 동료 {count}인이{"\n"}
                  피드백하기를 {name} 님은
                </Text>
                <View style={styles.tagContainer}>
                  <Text style={styles.tagText}>
                    {reportData[0].map((item) => item[0]).join(", ")}
                  </Text>
                </View>
                <Text style={styles.tagEndText}>사람입니다.</Text>
                <View style={styles.divider}></View>
                <View style={styles.cloudContainer}>
                  <View style={styles.cloudItem}>
                    <View style={styles.cloudHeader}>
                      <Text style={styles.korean}>강점 태그 클라우드</Text>
                    </View>
                    {/* <Canvas paint={<Wordcloud />}></Canvas> */}
                    <Image style={styles.cloudImage} src={strengthUrl}></Image>
                  </View>
                  <View style={styles.cloudItem}>
                    <View style={styles.cloudHeader}>
                      <Text style={styles.korean}>가치 태그 클라우드</Text>
                    </View>
                    {/* <Wordcloud ref={canvasRef} setIsLoaded={setIsLoaded} />; */}
                    <Image style={styles.cloudImage} src={url}></Image>
                  </View>
                </View>
              </View>
              <View style={styles.pageFooter} fixed={true}>
                <View style={styles.row}>
                  <Image
                    style={styles.footerLogo}
                    src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                  ></Image>
                  <View style={styles.footerSeperator}></View>
                  <View>
                    <Text style={styles.footerText}>베러댄피드백™</Text>
                    <Text style={styles.footerCopyRight}>
                      Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                      All rights reserved.
                    </Text>
                  </View>
                </View>
                <Text
                  style={styles.footerText}
                  render={({ pageNumber }) => `${pageNumber}`}
                />
              </View>
            </Page>
            <Page size="A4" style={styles.page}>
              <View style={styles.pageTop}></View>
              <View style={{ paddingHorizontal: 20 }}>
                <View style={styles.strengthContainer}>
                  <Text style={styles.strenghtHeading}>
                    <Text style={{ fontWeight: 700 }}>{name}</Text> 님은
                    감성적인 면에서
                  </Text>
                  <View style={styles.strengthWordsContainer}>
                    {reportData[2].map((item, index) => {
                      if (item[1] >= 3) {
                        return (
                          <Text style={styles.strengthWordHighLight}>
                            {`${item[0]}`}
                            <Text
                              style={{ fontSize: 9 }}
                            >{`(${item[1]})`}</Text>
                            {index === reportData[2].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else if (item[1] === 2) {
                        return (
                          <Text style={styles.strengthWordBold}>
                            {item[0]}
                            {index === reportData[2].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else {
                        return (
                          <Text style={styles.strengthWordLight}>
                            {item[0]}
                            {index === reportData[2].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      }
                    })}
                  </View>
                  <Text
                    style={{ ...styles.strenghtHeading, textAlign: "right" }}
                  >
                    강점이 보입니다.
                  </Text>
                </View>
                <View style={styles.strengthContainer}>
                  <Text style={styles.strenghtHeading}>
                    <Text style={{ fontWeight: 700 }}>{name}</Text> 님은
                    인지적인 면에서
                  </Text>
                  <View style={styles.strengthWordsContainer}>
                    {reportData[3].map((item, index) => {
                      if (item[1] >= 3) {
                        return (
                          <Text style={styles.strengthWordHighLight}>
                            {`${item[0]}`}
                            <Text
                              style={{ fontSize: 9 }}
                            >{`(${item[1]})`}</Text>
                            {index === reportData[3].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else if (item[1] === 2) {
                        return (
                          <Text style={styles.strengthWordBold}>
                            {item[0]}
                            {index === reportData[3].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else {
                        return (
                          <Text style={styles.strengthWordLight}>
                            {item[0]}
                            {index === reportData[3].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      }
                    })}
                  </View>
                  <Text
                    style={{ ...styles.strenghtHeading, textAlign: "right" }}
                  >
                    강점이 보입니다.
                  </Text>
                </View>
                <View style={styles.strengthContainer}>
                  <Text style={styles.strenghtHeading}>
                    <Text style={{ fontWeight: 700 }}>{name}</Text> 님은
                    행동적인 면에서
                  </Text>
                  <View style={styles.strengthWordsContainer}>
                    {reportData[4].map((item, index) => {
                      if (item[1] >= 3) {
                        return (
                          <Text style={styles.strengthWordHighLight}>
                            {`${item[0]}`}
                            <Text
                              style={{ fontSize: 9 }}
                            >{`(${item[1]})`}</Text>
                            {index === reportData[4].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else if (item[1] === 2) {
                        return (
                          <Text style={styles.strengthWordBold}>
                            {item[0]}
                            {index === reportData[4].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else {
                        return (
                          <Text style={styles.strengthWordLight}>
                            {item[0]}
                            {index === reportData[4].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      }
                    })}
                  </View>
                  <Text
                    style={{ ...styles.strenghtHeading, textAlign: "right" }}
                  >
                    강점이 보입니다.
                  </Text>
                </View>
                <View style={styles.strengthContainer}>
                  <Text style={styles.strenghtHeading}>
                    <Text style={{ fontWeight: 700 }}>{name}</Text> 님은
                    일하면서
                  </Text>
                  <View style={styles.strengthWordsContainer}>
                    {reportData[1].map((item, index) => {
                      if (item[1] >= 3) {
                        return (
                          <Text style={styles.strengthWordHighLight}>
                            {`${item[0]}`}
                            <Text
                              style={{ fontSize: 9 }}
                            >{`(${item[1]})`}</Text>
                            {index === reportData[1].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else if (item[1] === 2) {
                        return (
                          <Text style={styles.strengthWordBold}>
                            {item[0]}
                            {index === reportData[1].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      } else {
                        return (
                          <Text style={styles.strengthWordLight}>
                            {item[0]}
                            {index === reportData[1].length - 1 ? (
                              <Text></Text>
                            ) : (
                              <Text>,</Text>
                            )}
                          </Text>
                        );
                      }
                    })}
                  </View>
                  <Text
                    style={{ ...styles.strenghtHeading, textAlign: "right" }}
                  >
                    을 중요하게 생각합니다.
                  </Text>
                </View>
              </View>
              <Text style={styles.strengthGuideText}>
                * 3명 이상 동일한 응답은 주황색으로, 2명 이상 동일한 응답은
                굵은글씨로 표시됩니다.
              </Text>
              <View style={styles.pageFooter} fixed={true}>
                <View style={styles.row}>
                  <Image
                    style={{ ...styles.footerLogo }}
                    src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                  ></Image>
                  <View style={styles.footerSeperator}></View>
                  <View>
                    <Text style={styles.footerText}>베러댄피드백™</Text>
                    <Text style={styles.footerCopyRight}>
                      Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                      All rights reserved.
                    </Text>
                  </View>
                </View>
                <Text
                  style={styles.footerText}
                  render={({ pageNumber }) => `${pageNumber}`}
                />
              </View>
            </Page>
            <Page
              size="A4"
              style={{ ...styles.page, paddingBottom: "80px" }}
              wrap={true}
            >
              <View style={styles.pageTop} fixed></View>
              <View style={{ paddingHorizontal: 20 }}>
                <View style={styles.commentContainer}>
                  <Text>동료들이 전하는 고마운 마음입니다 😊</Text>
                  <View style={styles.commentDivider}></View>
                  {reportData[5].map((item, index) => (
                    <Text style={styles.commentText}>{item}</Text>
                  ))}
                </View>
                <View style={{ height: 60 }}></View>
                <View style={styles.commentContainer}>
                  <Text>동료들이 기대하는 마음입니다 🙏</Text>
                  <View style={styles.commentDivider}></View>
                  {reportData[6].map((item, index) => (
                    <Text style={styles.commentText}>{item}</Text>
                  ))}
                </View>
              </View>
              <View style={styles.pageFooter} fixed>
                <View style={styles.row}>
                  <Image
                    style={styles.footerLogo}
                    src={`${process.env.PUBLIC_URL}/assets/betterthan_footer.png`}
                  ></Image>
                  <View style={styles.footerSeperator}></View>
                  <View>
                    <Text style={styles.footerText}>베러댄피드백™</Text>
                    <Text style={styles.footerCopyRight}>
                      Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY.
                      All rights reserved.
                    </Text>
                  </View>
                </View>
                <Text
                  style={styles.footerText}
                  render={({ pageNumber }) => `${pageNumber}`}
                />
              </View>
            </Page>
          </Document>
        }
        fileName={`${id}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          console.log(blob);
          return loading ? (
            "Loading..."
          ) : (
            <button
              onClick={uploadPdf(blob, `${id}.pdf`)}
              style={{ display: "none" }}
            ></button>
          );
        }}
      </BlobProvider>
      {/* </PDFViewer> */}
    </Container>
  );
};

export default ReactPdf;
