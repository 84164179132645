import styled from "styled-components";

const COLORS = [
  "#CC2B69",
  "#ED2B2B",
  "#FF812C",
  "#FFC842",
  "#DAE233",
  "#00C0E0",
  "#7CA1D3",
  "#B782B9",
];
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 793.7007874px;
  height: 1122.519685px;

  color: white;
  // border: 1px solid black;
  position: relative;
`;

const PageCover = styled.div`
  width: 740px;
  height: 1030px;
  position: absolute;
  top: 0;
  left: 0;
  // border: 1px solid black;
  border-radius: 0 0 120px 0;
  background-color: #ff812c;
`;
const PageCoverImage = styled.img`
  width: 740px;
  height: 1030px;
  position: absolute;
  top: 0;
  left: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 66.5px;
  padding-top: 76.6px;
  position: relative;
`;

const Logo = styled.img`
  // width: 102.8px;
  height: 25px;
  // margin: 0 412.7px 70.9px 0;
  object-fit: contain;
  position: absolute;
  bottom: 25px;
  right: 50px;
  // border: 1px solid black;
`;

const ColumnLeft = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
`;

const Title = styled.div`
  // width: 324px;
  // height: 122px;
  margin: 0 4.9px 13px 0.1px;
  font-family: PretendardVariable;
  font-size: 60px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -2.08px;
  text-align: left;
  color: #fff;
  // border: 1px solid black;
  display: flex;
`;

const Phrase = styled.p`
  // width: 307px;
  // height: 19px;

  font-family: PretendardVariable;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.48px;
  text-align: left;
  color: #fff;
`;

const SubTitle = styled.span`
  // width: 264px;
  // height: 59px;
  margin: 0 62.9px 8px 0.1px;
  font-family: PretendardVariable;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.88px;
  text-align: left;
  color: #fff;
`;

const ProjectText = styled.span`
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`;

const DateText = styled.span`
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
`;

const Border = styled.div`
  width: 50px;
  height: 0;
  margin-top: 93px;
  margin-bottom: 27px;
  border: solid 3px #fff;
`;

const ColumnRight = styled(ColumnLeft)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NameContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 47px;
  margin-bottom: 137px;
`;
const Name = styled.span`
  // width: 113px;
  // height: 53px;
  margin: 0 12px 0 0;
  font-family: PretendardVariable;
  font-size: 42px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 50px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #fff;
`;

const Span = styled.span`
  // width: 26px;
  // height: 35px;
  font-family: PretendardVariable;
  font-size: 28px;
  font-weight: 500;
  line-height: 33.6px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #fff;
`;

const FooterText = styled.p`
  font-family: PretendardVariable;
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #666666;
  width: 500px;
  position: absolute;
  bottom: 23px;
  left: 28px;
  // border: 1px solid black;
`;

function ReportCover({ name, title, project }) {
  console.log(name, title, project);
  return (
    <Container>
      <PageCover />
      <PageCoverImage
        src={`${process.env.PUBLIC_URL}/assets/front-cover.png`}
      />
      {/* <HeaderTopContainer>
        {COLORS.map((color, index) => (
          <HeaderTopBorder key={index} bgColor={color} />
        ))}
      </HeaderTopContainer> */}
      <Wrapper>
        <Title>
          베러댄피드백
          <br />
          리포트
          <span
            style={{
              fontSize: "41px",
              lineHeight: "49px",
              fontWeight: 600,
            }}
          >
            ™
          </span>
        </Title>
        <Phrase>Better Than Feedback Report</Phrase>

        <Border />
        <ProjectText>{project}</ProjectText>
        {/* <DateText>
          {`${new Date().getFullYear()}. ${
            new Date().getMonth() + 1
          }. ${new Date().getDate()}`}
        </DateText> */}
        {/* <ProjectText>신임 과장 승진자</ProjectText> */}
        <DateText>2022. 12. 6</DateText>

        <NameContainer>
          <Name>{name}</Name>
          <Span>님</Span>
        </NameContainer>
        <div>
          <FooterText>
            <span style={{ fontWeight: 500 }}>베러댄피드백™</span>은 주도적인
            성장을 지원하는 긍정적인 피드백 도구로, 함께 일하는 동료들이 바라본{" "}
            {name} 님의 긍정적인 키워드와 기대하는 모습을 담았습니다.
            베러댄피드백이 {name} 님의 성장에 도움이 되길 기대합니다.
          </FooterText>
        </div>
        <Logo src={`${process.env.PUBLIC_URL}/assets/betterthan-logo3x.png`} />
      </Wrapper>
    </Container>
  );
}

export default ReportCover;
