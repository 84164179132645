import React, { useState } from "react";
import UploadPDFModal from "./UploadPDFModal";

const TableRow = ({
  item,
  index,
  handleEdit,
  handleDelete,
  formatDateToKST,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handlePDFUpload = async (file) => {
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("id", item._id);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_DOMAIN}/bloom/mwc/upload-pdf`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();

      if (result.ok) {
        alert("PDF 업로드 성공");
      } else {
        alert(`PDF 업로드 실패: ${result.message}`);
      }
    } catch (error) {
      console.error("PDF 업로드 중 오류 발생:", error);
      alert("PDF 업로드 중 오류가 발생했습니다.");
    } finally {
      setShowUploadModal(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #d9d9d9",
        height: "66px",
      }}
    >
      <div style={{ flex: 0.5, textAlign: "center" }}>{index}</div>
      <div style={{ flex: 1, textAlign: "center" }}>{item.name}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{item.phone}</div>
      <div style={{ flex: 3, textAlign: "center" }}>{item.email}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{item.organization}</div>
      <div style={{ flex: 3, textAlign: "center" }}>{item.course}</div>
      <div style={{ flex: 2.5, textAlign: "center" }}>
        {formatDateToKST(item.courseStartDate)}
      </div>
      <div style={{ flex: 2.5, textAlign: "center" }}>
        {formatDateToKST(item.courseEndDate)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>{item.courseHour}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{item.certificateId}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{item.fts[0]}</div>
      <div style={{ flex: 2, textAlign: "center" }}>{item.fts[1]}</div>
      <button
        onClick={() => setShowUploadModal(true)}
        style={{
          flex: 1,
          padding: "8px 0px",
          backgroundColor: "#2196f3",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        등록
      </button>
      <button
        onClick={() => handleEdit(item._id)}
        style={{
          flex: 1,
          padding: "8px 0px",
          backgroundColor: "#4caf50",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        수정
      </button>
      <button
        onClick={() => handleDelete(item._id)}
        style={{
          flex: 1,
          padding: "8px 0px",
          backgroundColor: "#f44336",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        삭제
      </button>
      <UploadPDFModal
        show={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUpload={handlePDFUpload}
      />
    </div>
  );
};

export default TableRow;
