import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  getCollectorRecipientsByCollectorId,
  getMaumCheckupNameWithResponses,
  getMaumCheckupResponses,
  getParticipants,
  getRecipients,
} from "../api";
import { checkupResultAtom } from "../atom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 280px;
  padding: 0 40px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const Item = styled.div`
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  // border: 1px solid black;
  &:nth-child(1) {
    flex: 1;
  }
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(4) {
    flex: 1;
  }
  flex: 3;
  &:last-child {
    flex: 8;
    // border: 1px solid black;
  }
`;

const Button = styled.button`
  padding: 10px;
  width: 100px;
  cursor: pointer;
  border: none;
  background-color: #ff812c;
  color: white;
  font-weight: bold;
`;

function CheckupDetail() {
  const { projectId, collectorId } = useParams();
  // console.log(useParams());
  const {
    state: { week, results, collectors, projectKey },
  } = useLocation();
  const navigate = useNavigate();
  //   const [checkupResponse, setCheckupResponse] =
  //     useRecoilState(checkupResultAtom);
  const [responses, setResponses] = useState();
  const [participants, setParticipants] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // console.log("Checkup Detail State =>", week, results);

  const getCheckupResponseFromDB = async (email) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkup?email=${email}`
    );
    return response.data[0];
  };

  // const handleClick = async (email) => {
  //   const dest = [];

  //   for (let i = 0; i <= week; i++) {
  //     let isExist = false;
  //     for (let j = 0; j < results.length; j++) {
  //       if (
  //         results[j].collectorId === collectors[i] &&
  //         results[j].email === email
  //       ) {
  //         isExist = true;
  //         dest.push(results[j]);
  //         break;
  //       }
  //     }
  //     if (!isExist) {
  //       dest.push(null);
  //     }
  //   }

  //   navigate("report", {
  //     state: {
  //       email,
  //       week,
  //       result: dest,
  //     },
  //   });
  // };
  const handleFilterChange = (e) => {
    setIsChecked(!isChecked);

    if (e.target.value === "false") {
      setParticipants(participants.filter((p) => !p.isDone));
    } else {
      setParticipants(allParticipants);
    }
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      const response = await getParticipants(projectId);
      const participantsResult = results
        .filter((r) => r.collectorId === collectorId)
        .map((i) => i.key); // TODO: email 대신 키값으로 필터
      console.log(results);
      console.log(response);
      for (let i = 0; i < response.length; i++) {
        if (participantsResult.includes(response[i][projectKey])) {
          response[i].isDone = true;
        } else {
          response[i].isDone = false;
        }
        response[
          i
        ].link = `https://bloom-report.vercel.app/checkup/${projectId}/${
          response[i]._id
        }/${week + 1}`;
      }
      setParticipants(response);
      setAllParticipants(response);
    };
    fetchParticipants();
  }, []);

  // console.log("Checkup Detail => ", checkupResponse);
  return (
    <Container>
      <Title>{week + 1}주차 리포트</Title>
      <label>
        <input
          type="checkbox"
          value={isChecked}
          onChange={handleFilterChange}
        />
        미응답자
      </label>

      <List>
        <Item>
          <Column>이름</Column>
          <Column>사원번호</Column>
          <Column>이메일</Column>
          <Column>응답상태</Column>
          <Column>리포트 링크</Column>
        </Item>
        {participants.length > 0 &&
          participants.map((p, index) => {
            return (
              <Item key={index}>
                <Column>{p.name}</Column>
                <Column>{p.companyPersonalId}</Column>
                <Column>{p.email}</Column>
                <Column>{p.isDone ? "완료" : "미응답"}</Column>
                <Column>{p.isDone && <a href={p.link}>{p.link}</a>}</Column>
              </Item>
            );
          })}
      </List>
      {/* <List>
        {results
          .filter((r) => r.collectorId === collectorId)
          .map((result, index) => {
            return (
              <Item key={index}>
                <Column>
                  <span>{result.name}</span>
                  <span>{result.email}</span>
                </Column>
                <Column>
                  <Button onClick={() => handleClick(result.email)}>
                    리포트 보기
                  </Button>
                </Column>
              </Item>
            );
          })}
      </List> */}
    </Container>
  );
}

export default CheckupDetail;
