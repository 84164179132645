import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  getCoreRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
  useReactTable,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
  getFacetedMinMaxValues,
} from "@tanstack/react-table";
import ProjectSummaryTable from "../../components/betterThanFeedback/ProjectSummaryTable";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import { FEEDBACK_TYPE } from "../../constants";
import ReactPdf from "../../components/ReactPdf";
import * as XLSX from "xlsx";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  // border: 1px solid black;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  justify-content: space-between;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  padding-top: 50px;
  width: 50%;
  height: 600px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const ProjectTable = styled.table`
  margin-top: 50px;
  width: 100%;
  // border: 1px soild black;
`;

const ProjectHeader = styled.thead`
  width: 100%;
  font-weight: 700;
  border-bottom: 1px solid black;
`;

const ProjectHeaderColumn = styled.th`
  padding-bottom: 10px;
`;

const ProjectRow = styled.tr``;

const ProjectRowColumn = styled.td`
  flex: ${({ flex }) => flex || 1};
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const CheckButtonContainer = styled.div`
  display: flex;
`;
const SendButtonContainer = styled.div``;

const SendButton = styled.button`
  margin: 0 5px;
`;

const LastUpdateText = styled.p`
  margin-top: 50px;
`;

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

function TableHeader({ header }) {
  // console.log(header);
  const sortedUniqueValues = useMemo(
    () => Array.from(header.column.getFacetedUniqueValues().keys()).sort(),
    [header.column]
  );
  const columnFilterValue = header.column.getFilterValue();
  // console.log(header.column.getFacetedMinMaxValues());
  // console.log(sortedUniqueValues);
  const onFilterChange = (value) => {
    if (value === "null") {
      header.column.setFilterValue(null);
    } else {
      header.column.setFilterValue(value);
    }
  };
  return (
    <ProjectHeaderColumn
      key={header.id}
      style={{
        width: header.getSize(),
        cursor: header.column.getCanSort() ? "pointer" : "default",
      }}
      onClick={header.column.getToggleSortingHandler()}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getCanFilter() && header.id === "group" ? (
        <select
          onChange={({ currentTarget: { value } }) => onFilterChange(value)}
        >
          <option value="null">전체</option>
          {sortedUniqueValues.map((value) => (
            <option key={value}>{value}</option>
          ))}
        </select>
      ) : header.column.getCanFilter() && header.id === "sendCounts" ? (
        <DebouncedInput
          type="number"
          // min={Number(header.column.getFacetedMinMaxValues()?.[0] ?? "")}
          // max={Number(header.column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={columnFilterValue?.[1] ?? ""}
          onChange={(value) =>
            header.column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`입력한 수 이하의 데이터 필터링`}
          className="w-24 border shadow rounded"
        />
      ) : header.column.getCanFilter() && header.id === "응답 수" ? (
        <>
          {/* <DebouncedInput
            type="number"
            // min={Number(header.column.getFacetedMinMaxValues()?.[0] ?? "")}
            // max={Number(header.column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) => {
              header.column.setFilterValue((old) => [value, old?.[1]]);
            }}
            placeholder={``}
            className="w-24 border shadow rounded"
          /> */}
          <DebouncedInput
            type="number"
            // min={Number(header.column.getFacetedMinMaxValues()?.[0] ?? "")}
            // max={Number(header.column.getFacetedMinMaxValues()?.[1] ?? "")}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(value) => {
              header.column.setFilterValue((old) => [old?.[0], value]);
            }}
            placeholder={`n 이하의 응답수`}
            className="w-24 border shadow rounded"
          />
        </>
      ) : header.column.getCanFilter() && header.id === "birthday" ? (
        <select
          onChange={({ currentTarget: { value } }) =>
            onFilterChange(() => value)
          }
        >
          <option value="null">전체</option>
          {["있음", "없음"].map((value) => (
            <option key={value}>{value}</option>
          ))}
        </select>
      ) : header.column.getCanFilter() && header.id === "name" ? (
        <input
          type="text"
          value={header.column.getFilterValue() ?? ""}
          onChange={(e) => header.column.setFilterValue(e.target.value)}
          placeholder={`Search...`}
          className="w-36 border shadow rounded"
        />
      ) : null}
      {/* {
        {
          asc: <FaSortUp />,
          desc: <FaSortDown />,
        }[header.column.getIsSorted()]
      }
      {header.column.getCanSort() && !header.column.getIsSorted() ? (
        <FaSort />
      ) : null} */}
    </ProjectHeaderColumn>
  );
}

const EditableCell = ({ row }) => {
  const [value, setValue] = useState("");

  const sendEmail = async (data, email) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/pdf-email`,
      {
        name: row.original.name,
        link: row.original.pdfLink,
        email: value.length > 0 ? value : row.original.email,
      }
    );
    if (response.data.ok) {
      alert("이메일이 발송됐습니다.");
    } else {
      alert(`문제가 발생했습니다. ${response.data.err}`);
    }
  };
  return (
    <>
      <input value={value} onChange={(e) => setValue(e.target.value)} />{" "}
      <button onClick={() => sendEmail(row.original, value)}>
        리포트 발송
      </button>
    </>
  );
};

function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}

const BetterThanFeedbackDetail = () => {
  const [search] = useSearchParams();
  const pid = search.get("pid");

  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [project, setProject] = useState();
  const [rows, setRows] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [groupFilter, setGroupFilter] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [showCheckBox, setShowCheckbox] = useState(false);
  const [showFriendRemind, setShowFriendRemind] = useState(false);
  const [showParticipantRemind, setShowParticipantRemind] = useState(false);
  const [checks, setChecks] = useState([]);
  const [remindEmails, setRemindEmails] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [friendRemindCheck, setFriendRemindCheck] = useState(false);
  const [participantRemindCheck, setParticipantRemindCheck] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({});
  const [friends, setFriends] = useState([]);
  const [click, setClick] = useState();
  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [newParticipantName, setNewParticipantName] = useState();
  const [newParticipantEmail, setNewParticipantEmail] = useState();
  const [newParticipantDepartment, setNewParticipantDepartment] = useState();
  const [isAllDownload, setIsAllDownload] = useState(false);
  const [remindLogs, setRemindLogs] = useState([]);
  const [showRemindLogs, setShowRemindLogs] = useState(false);

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const [rowSelection, setRowSelection] = useState({});

  const columnHelper = createColumnHelper();

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    columnHelper.accessor("name", {
      header: "이름",
      enableSorting: false,
      sortingFn: "auto",
      enableColumnFilter: true,
      filterFn: "includesString",
    }),
    columnHelper.accessor("email", {
      header: "이메일",
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("department", {
      header: "팀명",
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("birthday", {
      header: "생년월일",
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: "birthdayFilter",
    }),
    // columnHelper.accessor("group", {
    //   header: "차수",
    //   enableSorting: false,
    //   enableColumnFilter: false,
    //   filterFn: "weakEquals",
    // }),
    // columnHelper.accessor("sendDate", {
    //   header: "설문 시작일",
    //   cell: ({ renderValue }) => {
    //     return new Date(renderValue()).toLocaleDateString().slice(5);
    //   },
    //   enableSorting: false,
    //   enableColumnFilter: false,
    // }),
    // columnHelper.accessor(
    //   (row) => {
    //     return row.surveyEndDate;
    //   },
    //   {
    //     header: "설문 종료일",
    //     cell: ({ renderValue }) => {
    //       return new Date(renderValue()).toLocaleDateString().slice(5);
    //     },
    //     enableSorting: false,
    //     enableColumnFilter: false,
    //   }
    // ),
    // columnHelper.accessor("firstRemindDate", {
    //   header: "1차 리마인드",
    //   cell: ({ renderValue }) => {
    //     return new Date(renderValue()).toLocaleDateString().slice(5);
    //   },
    //   enableSorting: false,
    //   enableColumnFilter: false,
    // }),
    // columnHelper.accessor("secondRemindDate", {
    //   header: "2차 리마인드",
    //   cell: ({ renderValue }) => {
    //     return new Date(renderValue()).toLocaleDateString().slice(5);
    //   },
    //   enableSorting: false,
    //   enableColumnFilter: false,
    // }),
    columnHelper.accessor("friends.length", {
      id: "sendCounts",
      header: "발송 수",
      enableSorting: false,
      // enableColumnFilter: false,
    }),
    columnHelper.accessor(
      (row) => {
        return project?.feedbackType === FEEDBACK_TYPE.A
          ? row.count
          : row.count;
      },
      {
        header: "응답 수",
        enableSorting: false,
        enableColumnFilter: true,
      }
    ),
    columnHelper.accessor("remindCount", {
      id: "remindCount",
      header: "리마인드 수",
      enableSorting: false,
      // enableColumnFilter: false,
    }),
    columnHelper.accessor("구성원 보기", {
      id: "friendsList",
      cell: (props) => {
        return (
          <button
            onClick={() => {
              handleShowFriends(props.row.original.friends);
            }}
          >
            더보기
          </button>
        );
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor("리포트 업로드", {
      id: "downloadReport",
      cell: (props) => {
        return (
          // <button
          //   onClick={() => {
          //     sendReport(props.row.original);
          //   }}
          // >
          //   리포트 업로드
          // </button>
          <button onClick={() => handleClickDownload(props.row.index)}>
            업로드
          </button>
        );
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor("리포트 발송", {
      id: "sendReport",
      cell: EditableCell,
      // (props) => {
      //   return (
      //     // <button
      //     //   onClick={() => {
      //     //     sendReport(props.row.original);
      //     //   }}
      //     // >
      //     //   리포트 업로드
      //     // </button>
      //     <>
      //       <input type="text" value={email} onChange={handleEmailChange} />
      //       <button onClick={() => sendEmail(props.row.original, email)}>
      //         리포트 발송
      //       </button>
      //     </>
      //   );
      // },
      enableColumnFilter: false,
    }),
  ];

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    filterFns: {
      birthdayFilter: (rows, columnIds, filterValue) => {
        if (filterValue === "없음") {
          if (!rows.getValue(columnIds)) {
            return true;
          } else {
            return false;
          }
        } else if (filterValue === "있음") {
          if (rows.getValue(columnIds)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
    },
    state: {
      columnVisibility,
    },
    // enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  const columnData = [
    ...(showCheckBox ? [{ Header: "체크" }] : []),
    {
      accessor: "name",
      Header: "이름",
    },
    {
      accessor: "email",
      Header: "이메일",
    },
    {
      accessor: "department",
      Header: "팀명",
    },
    {
      accessor: "birthday",
      Header: "생년월일",
    },
    {
      accessor: "group",
      Header: "차수",
    },
    // {
    //   accessor: "surveyStartDate",
    //   Header: "설문 시작일",
    // },
    // {
    //   accessor: "surveyEndDate",
    //   Header: "설문 종료일",
    // },
    // {
    //   accessor: "firstRemindDate",
    //   Header: "1차 리마인드",
    // },
    // {
    //   accessor: "secondRemindDate",
    //   Header: "2차 리마인드",
    // },
    {
      accessor: "friends",
      Header: "발송 수",
    },
    {
      accessor: "replies",
      Header: "응답 수",
    },
    {
      accessor: "detail",
      Header: "구성원 보기",
    },
    {
      accessor: "report",
      Header: "업로드",
    },
    {
      accessor: "report email",
      Header: "리포트 발송",
    },
  ];

  const handleFilter = (condition) => {
    setGroupFilter(condition);
    setShowCheckbox(false);
    setShowFriendRemind(false);
    setFriendRemindCheck(false);
    setParticipantRemindCheck(false);
    setShowParticipantRemind(false);
    setFilteredParticipants(participants.filter((i) => i.group === condition));
    // console.log(participants);
    // const filtered = participants.filter((item) => item.group === condition);
    // console.log(filtered);
    // setRows(filtered);
  };

  const handleSendFriendRemind = async (round) => {
    if (table.getSelectedRowModel().rows.length === 0) {
      alert("리마인드 대상을 선택해주세요.");
      return;
    }

    const payload = [];

    switch (project.feedbackType) {
      case FEEDBACK_TYPE.A:
        table
          .getSelectedRowModel()
          .rows.map((i) => i.original)
          .forEach((item) => {
            const pId = item._id;
            const pName = item.name;
            item.friends.forEach((f) => {
              if (!f.reply) {
                payload.push({
                  id: pId,
                  name: pName,
                  email: f.email,
                  link: f.surveyLink,
                });
              }
            });
          });
        break;
      case FEEDBACK_TYPE.B:
        table
          .getSelectedRowModel()
          .rows.map((i) => i.original)
          .forEach((item) => {
            payload.push({
              name: item.name,
              email: item.email,
              link: item.surveyTinyUrl,
            });
          });
      default:
        break;
    }
    console.log(payload);
    if (project.feedbackType === FEEDBACK_TYPE.A && payload.length === 0) {
      alert("선택하신 응답자는 리마인드 조건에 해당되지 않습니다.");
      return;
    }
    setIsSending(true);
    // const emails = [];
    // if (checks.length === filteredParticipants.length) {
    //   for (let i = 0; i < checks.length; i++) {
    //     if (checks[i]) {
    //       console.log(i, filteredParticipants[i].name);
    //       filteredParticipants[i].friends.forEach((item) => {
    //         if (!item.reply) {
    //           emails.push({
    //             id: filteredParticipants[i]._id,
    //             name: filteredParticipants[i].name,
    //             email: item.email,
    //             link: item.surveyLink,
    //           });
    //         }
    //       });
    //     }
    //   }
    // }
    // console.log(project);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/remind`,
        {
          payload,
          pid: project.pid,
          feedbackType: project.feedbackType,
          type: "friend",
          round,
          company: project.company,
          projectName: project.projectName,
          surveyEndDate: project.surveyDates[groupFilter - 1].end,
        }
      );
    } catch (err) {
      console.log(err);
    } finally {
      alert("발송 완료");

      setIsSending(false);
      window.location.reload();
      // setRemindEmails(emails);
    }
    // console.log(emails);
  };

  const handleSendParticipantRemind = async () => {
    if (table.getSelectedRowModel().rows.length === 0) {
      alert("리마인드 대상을 선택해주세요.");
      return;
    }
    setIsSending(true);

    const payload = [];
    table
      .getSelectedRowModel()
      .rows.map((i) => i.original)
      .forEach((item) => {
        if (project.feedbackType === FEEDBACK_TYPE.B) {
          if (!item.birthday) {
            payload.push({
              id: item._id,
              name: item.name,
              email: item.email,
              link: item.formLink,
            });
          }
        } else {
          payload.push({
            id: item._id,
            name: item.name,
            email: item.email,
            link: item.formLink,
          });
        }
      });
    await axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/remind`,
      {
        payload,
        type: "participant",
        pid: project.pid,
        company: project.company,
        feedbackType: project.feedbackType,
        projectName: project.projectName,
        formStartDate: project.sendDates[groupFilter - 1],
        formEndDate: project.sendEndDates[groupFilter - 1],
        surveyEndDate: project.surveyDates[groupFilter - 1].end,
      }
    );
    setIsSending(false);
    alert("발송 완료");
    window.location.reload();
  };

  const handleCheckboxChange = (e) => {
    // console.log(e.target.id, e.target.checked);
    setChecks((cur) => {
      cur[e.target.id] = e.target.checked;
      return cur;
    });
  };

  const handleFriendsRemind = () => {
    if (!friendRemindCheck) {
      const filterParticipant = participants.filter(
        (p) =>
          p.group === groupFilter &&
          p.friends.length > 0 &&
          p.friends.length !==
            p.friends.filter((friend) => friend.reply === true).length &&
          p.friends.filter((friend) => friend.reply === true).length < 5
      );
      setShowCheckbox(true);
      setShowFriendRemind(true);
      setShowParticipantRemind(false);
      setParticipantRemindCheck(false);
      setFilteredParticipants(filterParticipant);
      setChecks(Array(filterParticipant.length).fill(true));
    } else {
      setFilteredParticipants(
        participants.filter((p) => p.group === groupFilter)
      );
      setShowCheckbox(false);
      setShowFriendRemind(false);
    }
    setFriendRemindCheck(!friendRemindCheck);
  };

  const handleFilterParticipantRemind = () => {
    if (!participantRemindCheck) {
      const filtered = participants.filter(
        (p) => p.group === groupFilter && p.friends.length === 0
      );
      setChecks(Array(filtered.length).fill(true));
      setFilteredParticipants(filtered);
      setShowParticipantRemind(true);
      setShowFriendRemind(false);
      setFriendRemindCheck(false);
      setShowCheckbox(true);
    } else {
      setFilteredParticipants(
        participants.filter((p) => p.group === groupFilter)
      );
      setShowCheckbox(false);
      setShowParticipantRemind(false);
    }

    setParticipantRemindCheck(!participantRemindCheck);
  };

  const handleShowFriends = (friends) => {
    setOpenModal(true);
    // console.log(filteredParticipants[i]);
    // setSelectedIndex(i);
    setFriends(friends);
  };

  const sendReport = (data) => {
    // console.log("!!!!!!", project.projectName);
    navigate("/report", {
      state: {
        name: data.name,
        pid: data.pid,
        projectName: project.projectName,
        projectDate: project.educationStartDate[groupFilter - 1],
      },
    });
  };

  const sendEmail = async (data, email) => {
    console.log(data, email);
  };

  const handleClickDownload = async (index) => {
    setIsAllDownload(false);
    setClick(index);
  };

  const handleAddParticipantClick = () => {
    setShowAddParticipant(true);
  };

  const handleNameChange = (e) => {
    setNewParticipantName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setNewParticipantEmail(e.target.value);
  };
  const handleDepartmentChange = (e) => {
    setNewParticipantDepartment(e.target.value);
  };

  const handleDownloadNotSendParticipant = () => {
    const targets = participants.filter((p) => p.friends.length === 0);
    const data = XLSX.utils.aoa_to_sheet([
      ["이름", "이메일", "부서"],
      ...targets.map((t) => [t.name, t.email, t.department]),
    ]);
    data["!cols"] = [{ wpx: 60 }, { wpx: 200 }, { wpx: 60 }];
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, data, "users");
    XLSX.writeFile(book, `[${project.projectName}] 미발송자 명단.xlsx`);
    // const lists =
  };

  const addParticipant = async () => {
    const participants = [
      {
        name: newParticipantName,
        email: newParticipantEmail,
        department: newParticipantDepartment,
        group: groupFilter,
      },
    ];
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/participants`,
      {
        participants,
        pid,
      }
    );

    setParticipants(response.data.participants);
    setFilteredParticipants(
      response.data.participants.filter((p) => p.group === groupFilter)
    );
    setData(response.data.participants);
    setShowAddParticipant(false);
  };

  const handleDeleteParticipantClick = async () => {
    console.log(table.getSelectedRowModel().rows);

    const participants = table
      .getSelectedRowModel()
      .rows.map((item) => item.original._id);

    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/participants`,
      {
        data: {
          participants,
          pid,
        },
      }
    );
    if (response.data.ok) {
      alert("유저가 삭제됐습니다.");
      window.location.reload();
      // setParticipants(response.data.participants);
      // setData(response.data.participants);
    }
  };

  const handleRemindLogClick = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/remindLog?pid=${project.pid}`
    );

    if (!response.data.ok) {
      return;
    }
    setRemindLogs(response.data.data.logs);
    setShowRemindLogs(true);
  };
  const getSendRate = () => {
    let rate = 0;
    switch (project.feedbackType) {
      case FEEDBACK_TYPE.A:
        rate = Number(
          (participants
            .filter((p) => p.group === groupFilter)
            .filter((p) => p.friends.length > 0).length /
            participants.filter((p) => p.group === groupFilter).length) *
            100
        ).toFixed(2);
        break;
      case FEEDBACK_TYPE.B:
        rate = Number(
          (participants.filter((p) => p.birthday).length /
            participants.length) *
            100
        ).toFixed(2);
        break;
      default:
        rate = rate.toFixed(2);
        break;
    }

    return rate;
  };

  const getCompleteRate = () => {
    let rate = 0;

    switch (project.feedbackType) {
      case FEEDBACK_TYPE.A:
        // rate = Number(
        //   (participants
        //     .filter((p) => p.group === groupFilter)
        //     .filter(
        //       (p) =>
        //         p.friends.filter((friend) => friend.reply === true).length >=
        //           5 ||
        //         (p.friends.length > 0 &&
        //           p.friends.filter((friend) => friend.reply === true).length ===
        //             p.friends.length)
        //     ).length /
        //     participants.filter((p) => p.group === groupFilter).length) *
        //     100
        // ).toFixed(2);
        rate = Number(
          (participants
            .filter((p) => p.group === groupFilter)
            .filter(
              (p) =>
                p.count >= 5 ||
                (p.friends.length > 0 && p.count === p.friends.length)
            ).length /
            participants.filter((p) => p.group === groupFilter).length) *
            100
        ).toFixed(2);
        break;
      case FEEDBACK_TYPE.B:
        rate = Number(
          (participants.filter((p) => p.count >= 5).length /
            participants.length) *
            100
        ).toFixed(2);
        break;
      default:
        break;
    }

    return rate;
  };
  const getReplyRate = () => {
    let rate = 0;

    switch (project.feedbackType) {
      case FEEDBACK_TYPE.A:
        const friendsCount = participants.reduce(
          (prev, cur) => prev + cur.friends.length,
          0
        );
        const friends = participants.map((p) => p.friends);
        let replyCount = 0;
        // for (let i = 0; i < friends.length; i++) {
        //   replyCount += friends[i].filter((f) => f.reply).length;
        // }
        replyCount = participants.reduce((prev, cur) => prev + cur.count, 0);
        rate =
          friendsCount !== 0 &&
          Number((replyCount / friendsCount) * 100).toFixed(2);
        break;

      case FEEDBACK_TYPE.B:
        rate = Number(
          (participants.filter((p) => p.count >= 5).length /
            participants.length) *
            100
        ).toFixed(2);
        break;
      default:
        break;
    }

    return rate;
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/participants?pid=${pid}`
      );

      const projectResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/projects?pid=${pid}`
      );

      // console.log(response.data.participants);

      setParticipants(response.data.participants);
      setFilteredParticipants(
        response.data.participants.filter((p) => p.group === groupFilter)
      );

      setProject(projectResponse.data.project);

      setData(response.data.participants);

      setIsLoading(false);
      setLastUpdateTime(new Date().toLocaleTimeString());

      // 현장직인 경우, 발송수 의미 없음. 따라서 테이블에서 숨김처리
      if (projectResponse.data.project.feedbackType === FEEDBACK_TYPE.B) {
        setColumnVisibility({ sendCounts: false, friendsList: false });
      }
    };
    fetchData();
  }, []);
  // console.log("??", showCheckBox);
  // console.log(project, filteredParticipants);
  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <LastUpdateText>마지막 업데이트: {lastUpdateTime}</LastUpdateText>
      <RadioButtonContainer>
        {project.sendDates.map((item, index) => (
          // <button key={index} onClick={() => handleFilter(index + 1)}>
          //   {index + 1}차수
          // </button>

          <div key={index} style={{ marginRight: "10px" }}>
            <input
              type="radio"
              id={index + 1}
              value={index + 1}
              name="group"
              defaultChecked={index + 1 === groupFilter}
              onChange={() => handleFilter(index + 1)}
              // disabled={disabled}
            />
            <label htmlFor={index + 1}>{index + 1}차수</label>
          </div>
        ))}
      </RadioButtonContainer>
      <ProjectSummaryTable
        project={project}
        participants={participants}
        group={groupFilter}
        sendRate={getSendRate()}
        completeRate={getCompleteRate()}
        replyRate={getReplyRate()}
      />

      <ButtonContainer>
        <div>
          <SendButton onClick={handleAddParticipantClick}>
            {" "}
            대상자 추가
          </SendButton>
          <SendButton onClick={handleDeleteParticipantClick}>
            {" "}
            대상자 삭제
          </SendButton>
          <SendButton onClick={handleRemindLogClick}>
            {" "}
            리마인드 로그 보기
          </SendButton>
        </div>
        <div>
          <SendButton onClick={() => handleDownloadNotSendParticipant()}>
            미발송자 명단 다운로드
          </SendButton>
          <SendButton onClick={() => handleSendParticipantRemind()}>
            대상자 리마인드 메일 발송
          </SendButton>
          <SendButton onClick={() => handleSendFriendRemind(1)}>
            1차 리마인드 메일 발송
          </SendButton>
          <SendButton onClick={() => handleSendFriendRemind(2)}>
            2차 리마인드 메일 발송
          </SendButton>
          <SendButton
            onClick={() => {
              setIsAllDownload(true);
              setClick(0);
            }}
          >
            모든 대상자 리포트 업로드
          </SendButton>
        </div>
      </ButtonContainer>

      <div>
        총 {table.getPrePaginationRowModel().rows.length}명 중{" "}
        {table.getSelectedRowModel().rows.length}명 선택
      </div>
      <ProjectTable>
        <ProjectHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <ProjectRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader header={header} key={header.id} />
              ))}
            </ProjectRow>
          ))}
        </ProjectHeader>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            return (
              <ProjectRow key={row.original.id}>
                {row.getVisibleCells().map((cell) => (
                  <ProjectRowColumn key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </ProjectRowColumn>
                ))}
                {click === index && (
                  <ReactPdf
                    name={row.original.name}
                    id={row.original._id}
                    pid={row.original.pid}
                    projectName={row.original.projectName}
                    projectDate={project.educationStartDate[0]}
                    hi={console.log("hi")}
                    // downloadDone={setShow((cur) => {
                    //   cur[index] = false;
                    //   // if (cur[index + 1]) {
                    //   //   cur[index + 1] = true;
                    //   // }
                    //   return [...cur];
                    // })}
                    downloadDone={() => {
                      setClick((prev) => {
                        if (isAllDownload) {
                          if (
                            table.getPreFilteredRowModel().rows.length - 1 ===
                            prev
                          ) {
                            return -1;
                          }
                          return prev + 1;
                        }
                        return -1;
                      });
                    }}
                  />
                )}
              </ProjectRow>
            );
          })}
        </tbody>
      </ProjectTable>
      {/* <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table> */}
      {/* <ButtonContainer>
        <div>
          <CheckButtonContainer>
            <div style={{ marginRight: "10px" }}>
              <input
                type="checkbox"
                id="participangRemind"
                name="participangRemind"
                value="교육생 리마인드 대상자"
                checked={participantRemindCheck}
                onChange={() => handleFilterParticipantRemind()}
              />
              교육생 리마인드 대상자
            </div>
            <div>
              <input
                type="checkbox"
                id="friendRemind"
                name="friendRemind"
                value="구성원 리마인드"
                checked={friendRemindCheck}
                onChange={() => handleFriendsRemind()}
              />
              구성원 리마인드 대상자
            </div>
          </CheckButtonContainer>
        </div>
        <SendButtonContainer>
          {friendRemindCheck && (
            <>
              <SendButton onClick={() => handleSendFriendRemind(1)}>
                1차 리마인드 메일 발송
              </SendButton>
              <SendButton onClick={() => handleSendFriendRemind(2)}>
                2차 리마인드 메일 발송
              </SendButton>
            </>
          )}
          {showParticipantRemind && (
            <SendButton onClick={() => handleSendParticipantRemind()}>
              교육생 리마인드 메일 발송
            </SendButton>
          )}
        </SendButtonContainer>
      </ButtonContainer> */}

      {/* <Table
        columns={columnData}
        data={filteredParticipants}
        showCheckBox={showCheckBox}
        handler={handleShowFriends}
        reportHandler={sendReport}
        onChange={handleCheckboxChange}
      /> */}

      {isSending && (
        <Modal>
          <span style={{ color: "white", fontSize: "40px" }}>
            메일 발송중....
          </span>
        </Modal>
      )}
      {openModal && (
        <Modal>
          <ModalContainer>
            <ProjectTable>
              <ProjectHeader>
                <ProjectRow>
                  {/* <ProjectHeaderColumn>이름</ProjectHeaderColumn> */}
                  <ProjectHeaderColumn>이메일</ProjectHeaderColumn>
                  <ProjectHeaderColumn>응답 현황</ProjectHeaderColumn>
                  <ProjectHeaderColumn>1차 리마인드 횟수</ProjectHeaderColumn>
                  <ProjectHeaderColumn>2차 리마인드 횟수</ProjectHeaderColumn>
                </ProjectRow>
              </ProjectHeader>
              <tbody>
                {friends.map((f, i) => {
                  return (
                    <ProjectRow key={i}>
                      {/* <ProjectRowColumn>{f.name}</ProjectRowColumn> */}
                      <ProjectRowColumn>{f.email || f.key}</ProjectRowColumn>
                      <ProjectRowColumn>{f.reply ? "O" : "X"}</ProjectRowColumn>
                      <ProjectRowColumn>{f.firstRemindCount}</ProjectRowColumn>
                      <ProjectRowColumn>{f.secondRemindCount}</ProjectRowColumn>
                    </ProjectRow>
                  );
                })}
              </tbody>
            </ProjectTable>
            <button
              style={{ marginTop: "10px" }}
              onClick={() => setOpenModal(false)}
            >
              닫기
            </button>
          </ModalContainer>
        </Modal>
      )}
      {showAddParticipant && (
        <Modal>
          <ModalContainer
            style={{
              justifyContent: "space-evenly",

              padding: "80px",
              height: "300px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <input
                type="text"
                value={newParticipantName}
                onChange={handleNameChange}
                placeholder="이름"
              />
              <input
                type="text"
                value={newParticipantEmail}
                onChange={handleEmailChange}
                placeholder="이메일"
              />
              <input
                type="text"
                value={newParticipantDepartment}
                onChange={handleDepartmentChange}
                placeholder="팀명"
              />
            </div>
            <div>
              <button onClick={addParticipant}>생성</button>
              <button onClick={() => setShowAddParticipant(false)}>닫기</button>
            </div>
          </ModalContainer>
        </Modal>
      )}
      {showRemindLogs && (
        <Modal>
          <ModalContainer
            style={{
              justifyContent: "space-between",
              padding: "40px",
              height: "60%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                padding: "10px 0",
                flexDirection: "column",
                gap: "20px",
                overflow: "scroll",
                marginBottom: "20px",
              }}
            >
              {remindLogs.map((item, index) => (
                <div
                  key={index}
                  style={{
                    paddingBottom: "20px",
                    borderBottom: "1px solid black",
                  }}
                >
                  {item.content}
                </div>
              ))}
            </div>

            <div>
              <button onClick={() => setShowRemindLogs(false)}>닫기</button>
            </div>
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
};

export default BetterThanFeedbackDetail;
