import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function PieChart({ results }) {
  const data = {
    datasets: [
      {
        // label: "# of Votes",
        data: results,
        backgroundColor: ["#FFC842", "#DAE233", "#00C0E0"],
        borderColor: [
          "#fff",
          // "rgba(255, 255, 255, 1)",
          // "rgba(255, 255, 255, 1)",
          // "rgba(255, 255, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: "auto",
        color: "white",
        font: {
          weight: "bold",
          size: 16,
        },
        formatter: (value, ctx) => {
          const total = ctx.chart.getDatasetMeta(0).total;
          let percentage = Number((value * 100) / total).toFixed(0) + "%";
          return percentage;
        },
      },
    },
  };
  return (
    <div style={{ zIndex: 0, width: "200px", height: "200px" }}>
      <Pie
        id="pieChart"
        data={data}
        options={options}
        width={"100px"}
        height={"100px"}
      />
    </div>
  );
}
