import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  getCoreRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
  useReactTable,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { getTypeformResponse } from "../../api";
import { searchResultAtom } from "../../atom";
import HeadSection from "../../components/HeadSection";
import CreateForm from "./CreateForm";
import { useMemo } from "react";

const Container = styled.div`
  // border: 1px solid black;
`;
const Wrapper = styled.div`
  // border: 1px solid red;
`;

const InputContainer = styled.div`
  margin-top: 100px;
  display: flex;
`;

const Input = styled.input`
  width: 500px;
  padding: 15px;
  font-size: 18px;
  border: 1px solid #e2e2e2;
  &:focus {
    outline: none;
    border: 1px solid #ff812c;
  }
`;

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #ff812c;
  color: white;
  font-weight: bold;
`;

const FloatingButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff812c;
  color: white;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  border-radius: 25px;
  // border: 1px solid black;
  font-size: 24px;
`;

const ProjectTable = styled.table`
  margin-top: 100px;
  width: 100%;
  // border: 1px soild black;
`;

const ProjectHeader = styled.thead`
  width: 100%;
  font-weight: 700;
  border-bottom: 1px solid black;
`;

const ProjectHeaderColumn = styled.th`
  padding-bottom: 10px;
`;

const ProjectRow = styled.tr``;

const ProjectRowColumn = styled.td`
  flex: ${({ flex }) => flex || 1};
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
`;

function TableHeader({ header }) {
  const sortedUniqueValues = useMemo(
    () => Array.from(header.column.getFacetedUniqueValues().keys()).sort(),
    [header.column]
  );

  const onFilterChange = (value) => {
    if (value === "null") {
      header.column.setFilterValue(null);
    } else {
      header.column.setFilterValue(value);
    }
  };
  return (
    <ProjectHeaderColumn
      key={header.id}
      style={{
        width: header.getSize(),
        cursor: header.column.getCanSort() ? "pointer" : "default",
      }}
      onClick={header.column.getToggleSortingHandler()}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getCanFilter() ? (
        <select
          onChange={({ currentTarget: { value } }) => onFilterChange(value)}
        >
          <option value="null">선택 안함</option>
          {sortedUniqueValues.map((value) => (
            <option key={value}>{value}</option>
          ))}
        </select>
      ) : null}
      {/* {
        {
          asc: <FaSortUp />,
          desc: <FaSortDown />,
        }[header.column.getIsSorted()]
      }
      {header.column.getCanSort() && !header.column.getIsSorted() ? (
        <FaSort />
      ) : null} */}
    </ProjectHeaderColumn>
  );
}

function PositiveFeedback() {
  // const [name, setName] = useState("");
  const [searchResult, setSearchResult] = useRecoilState(searchResultAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [projects, setProjects] = useState([]);
  const [csvArray, setCsvArray] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  // 각 columnHelper(혹은 Column Defs)들을 담은 배열
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("pid", {
      header: "프로젝트 번호",
      enableSorting: true,
      sortingFn: "auto",
      enableColumnFilter: false,
      filterFn: "equalsString",
    }),
    columnHelper.accessor("projectName", {
      header: "프로젝트 명",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("feedbackType", {
      header: "프로젝트 타입",
      enableColumnFilter: false,
    }),
    columnHelper.accessor("participantsCount", {
      header: "대상자 수",
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("surveyDates.length", {
      header: "총 차수",
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("담당자 리포트", {
      id: "report",
      cell: (props) => {
        // console.log(props);
        // console.log(props.row.getAllCells()[0].getValue());
        const pid = props.row.getAllCells()[0].getValue();
        return (
          <button onClick={() => navigate(`/manager/report?pid=${pid}`)}>
            리포트 보기
          </button>
        );
      },
      enableColumnFilter: false,
    }),
    columnHelper.accessor("자세히보기", {
      id: "actions",
      cell: (props) => {
        // console.log(props);
        // console.log(props.row.getAllCells()[0].getValue());
        const pid = props.row.getAllCells()[0].getValue();
        return <button onClick={() => handleDetailClick(pid)}>더보기</button>;
      },
      enableColumnFilter: false,
    }),
    // columnHelper.accessor("프로젝트 삭제", {
    //   id: "delete",
    //   cell: (props) => {
    //     // console.log(props);
    //     // console.log(props.row.original);
    //     const id = props.row.original._id;
    //     return <button onClick={() => handleDeleteClick(id)}>삭제하기</button>;
    //   },
    //   enableColumnFilter: false,
    // }),
  ];

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const handleCreateProject = () => {
    // show form dialog
    console.log("clicked");
    setShowCreateForm(true);
  };

  // const handleChange = (e) => {
  //   setName(e.target.value);
  // };

  const handleSurveyNameChange = (e) => {
    setSearchResult({
      keyword: e.target.value,
      surveyInfo: {},
      result: {},
    });
    setIsSearchDone(false);
  };
  // const handleClick = () => {
  //   navigate("/report", { state: { name } });
  // };
  const handleSurveyNameClick = async () => {
    setIsLoading(true);
    const typeformResponses = await getTypeformResponse(searchResult.keyword);
    console.log(typeformResponses);
    if (typeformResponses) {
      setSearchResult({
        keyword: searchResult.keyword,
        surveyInfo: {
          name: "22년 책임매니저 Change Mapper 과정",
          id: 123,
        },
        result: typeformResponses,
      });
    }

    setIsLoading(false);
    setIsSearchDone(true);
  };

  const handleDetailClick = (pid) => {
    navigate(`/betterthanfeedback/detail?pid=${pid}`);
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/projects`,
      {
        data: {
          projectId: id,
        },
      }
    );

    if (response.data.ok) {
      alert("프로젝트가 삭제됐습니다.");
      window.location.reload();
    } else {
      alert("오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    // 프로젝트 리스트 불러오기
    const fetchProjects = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan/projects`
      );
      console.log(response.data.projects);
      setProjects(response.data.projects);
      setData(response.data.projects);
      setIsLoading(false);
      // console.log(response);
    };

    fetchProjects();
  }, []);

  // console.log("Search Atom => ", searchResult);
  // console.log(csvArray);
  return (
    <Container>
      <Wrapper>
        <HeadSection title="베러댄피드백™" />

        <Button onClick={handleCreateProject}>새 프로젝트 생성</Button>

        <ProjectTable>
          <ProjectHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <ProjectRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeader header={header} key={header.id} />
                ))}
              </ProjectRow>
            ))}
          </ProjectHeader>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <ProjectRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <ProjectRowColumn key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </ProjectRowColumn>
                ))}
              </ProjectRow>
            ))}
          </tbody>
        </ProjectTable>
      </Wrapper>
      {showCreateForm && (
        <CreateForm
          setProjects={setData}
          // setDaata={setData}
          setCsvArray={setCsvArray}
          close={() => setShowCreateForm(false)}
        />
      )}
    </Container>
  );
}

export default PositiveFeedback;
