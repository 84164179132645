import styled from "styled-components";
import { FEEDBACK_TYPE } from "../../constants";

const Container = styled.div``;

const ProjectTable = styled.table`
  margin-top: 10px;
  width: 100%;
  // border: 1px soild black;
`;

const ProjectHeader = styled.thead`
  width: 100%;
  font-weight: 700;
  border-bottom: 1px solid black;
`;

const ProjectHeaderColumn = styled.th`
  padding-bottom: 10px;
`;

const ProjectRow = styled.tr``;

const ProjectRowColumn = styled.td`
  flex: ${({ flex }) => flex || 1};
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
`;

const ProjectSummaryTable = ({
  project,
  participants,
  group,
  sendRate,
  replyRate,
  completeRate,
}) => {
  const surveyEndDate = new Date(project.surveyDates[group - 1].end);
  const friendsCount = participants.reduce(
    (prev, cur) => prev + cur.friends.length,
    0
  );

  const repliedParticipants = participants.filter((p) => p.friends.length > 0);
  const zeroReply = participants.map((p) => p.friends);
  let zero = 0;
  let oneToFour = 0;
  let fiveToSeven = 0;
  let sevenAbove = 0;
  for (let i = 0; i < zeroReply.length; i++) {
    const temp = zeroReply[i].filter((f) => f.reply).length;
    if (temp === 0) {
      zero++;
    } else if (temp < 5) {
      oneToFour++;
    } else if (temp < 8) {
      fiveToSeven++;
    } else if (temp >= 8) {
      sevenAbove++;
    }
  }

  console.log(zeroReply);
  let min =
    repliedParticipants.length > 0
      ? repliedParticipants[0].friends.filter((p) => p.reply).length
      : 0;
  let max =
    repliedParticipants.length > 0
      ? repliedParticipants[0].friends.filter((p) => p.reply).length
      : 0;
  let sum = 0;

  for (let i = 0; i < repliedParticipants.length; i++) {
    let temp = repliedParticipants[i].friends.filter((p) => p.reply).length;
    sum += temp;
    if (temp < min) {
      min = temp;
    }
    if (temp > max) {
      max = temp;
    }
  }
  if (repliedParticipants.length > 0) {
    sum = Number(sum / repliedParticipants.length).toFixed(1);
  }

  const getNotEnoughReply = () => {
    let result = 0;
    switch (project.feedbackType) {
      case FEEDBACK_TYPE.A:
        result = participants
          .filter((p) => p.group === group)
          .filter(
            (p) =>
              p.friends.filter((friend) => friend.reply).length < 5 &&
              p.friends.length !==
                p.friends.filter((friend) => friend.reply).length
          ).length;
        break;

      case FEEDBACK_TYPE.B:
        result = participants.filter((p) => p.count < 5).length;

        break;
      default:
        break;
    }

    return result;
  };
  // let firstRemindDate = new Date(surveyEndDate.getTime() - 86400000);
  // let secondRemindDate = new Date(surveyEndDate.getTime() + 86400000);
  return (
    <Container>
      <ProjectTable>
        <ProjectHeader>
          <tr>
            <ProjectHeaderColumn>프로젝트 번호</ProjectHeaderColumn>
            <ProjectHeaderColumn>프로젝트 명</ProjectHeaderColumn>
            {/* <ProjectHeaderColumn>차수</ProjectHeaderColumn> */}
            <ProjectHeaderColumn>교육 날짜</ProjectHeaderColumn>
            <ProjectHeaderColumn>대상자 입력시작일</ProjectHeaderColumn>
            <ProjectHeaderColumn>대상자 입력마감일</ProjectHeaderColumn>
            <ProjectHeaderColumn>구성원 응답마감일</ProjectHeaderColumn>
            <ProjectHeaderColumn>리마인드 날짜</ProjectHeaderColumn>
          </tr>
        </ProjectHeader>
        <tbody>
          <ProjectRow>
            <ProjectRowColumn>{project.pid}</ProjectRowColumn>
            <ProjectRowColumn>{project.projectName}</ProjectRowColumn>
            {/* <ProjectRowColumn>{group}</ProjectRowColumn> */}
            <ProjectRowColumn>
              {new Date(
                project.educationStartDate[group - 1]
              ).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {new Date(project.sendDates[group - 1]).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {new Date(project.sendEndDates[group - 1]).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {surveyEndDate.toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              <p>
                1차:{" "}
                {new Date(
                  project.firstRemindDate[group - 1]
                ).toLocaleDateString()}
              </p>
              <p>
                2차:{" "}
                {new Date(
                  project.secondRemindDate[group - 1]
                ).toLocaleDateString()}
              </p>
            </ProjectRowColumn>
          </ProjectRow>
        </tbody>
      </ProjectTable>
      <div style={{ marginTop: "40px" }} />
      <ProjectTable>
        <ProjectHeader>
          <tr>
            <ProjectHeaderColumn>대상자 수</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답자 수</ProjectHeaderColumn>
            <ProjectHeaderColumn>발송률 (%)</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답률 (%)</ProjectHeaderColumn>
            <ProjectHeaderColumn style={{ width: "25%" }}>
              완수율 (%)
              <br />
              <p
                style={{
                  fontSize: "0.75rem",
                  color: "#777",
                  marginTop: "0.25rem",
                }}
              >
                *완수 기준 : '응답 수 5 이상' or '발송 수 = 응답 수' 일 때
              </p>
            </ProjectHeaderColumn>
          </tr>
        </ProjectHeader>

        <tbody>
          <ProjectRow>
            <ProjectRowColumn>{participants.length}명</ProjectRowColumn>
            <ProjectRowColumn>{friendsCount}명</ProjectRowColumn>
            <ProjectRowColumn>{sendRate}%</ProjectRowColumn>
            <ProjectRowColumn>{replyRate}%</ProjectRowColumn>
            <ProjectRowColumn>{completeRate}%</ProjectRowColumn>
          </ProjectRow>
        </tbody>
      </ProjectTable>
      <div style={{ marginTop: "40px" }} />
      <ProjectTable>
        <ProjectHeader>
          <tr>
            <ProjectHeaderColumn>미발송자</ProjectHeaderColumn>
            <ProjectHeaderColumn>최소 응답 수</ProjectHeaderColumn>
            <ProjectHeaderColumn>최대 응답 수</ProjectHeaderColumn>
            <ProjectHeaderColumn>평균 응답 수</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답 수 0명</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답 수 1~4명</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답 수 5~7명</ProjectHeaderColumn>
            <ProjectHeaderColumn>응답 수 8명 이상</ProjectHeaderColumn>
          </tr>
        </ProjectHeader>
        <tbody>
          <ProjectRow>
            <ProjectRowColumn>
              {participants.filter((p) => p.friends.length === 0).length}명
            </ProjectRowColumn>
            <ProjectRowColumn>{`${min}명`}</ProjectRowColumn>
            <ProjectRowColumn>{`${max}명`}</ProjectRowColumn>
            <ProjectRowColumn>{`${sum}명`}</ProjectRowColumn>
            <ProjectRowColumn>{zero}명</ProjectRowColumn>
            <ProjectRowColumn>{`${oneToFour}명`}</ProjectRowColumn>
            <ProjectRowColumn>{`${fiveToSeven}명`}</ProjectRowColumn>
            <ProjectRowColumn>{`${sevenAbove}명`}</ProjectRowColumn>
          </ProjectRow>
        </tbody>
      </ProjectTable>
    </Container>
  );
};

export default ProjectSummaryTable;
