import React from "react";

const TableHeader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      backgroundColor: "#f8f8f8",
      height: "53px",
      border: "1px solid #d9d9d9",
    }}
  >
    <div style={{ flex: 0.5, textAlign: "center" }}>연번</div>
    <div style={{ flex: 1, textAlign: "center" }}>성명</div>
    <div style={{ flex: 2, textAlign: "center" }}>휴대폰번호</div>
    <div style={{ flex: 3, textAlign: "center" }}>이메일</div>
    <div style={{ flex: 2, textAlign: "center" }}>소속</div>
    <div style={{ flex: 3, textAlign: "center" }}>교육과정명</div>
    <div style={{ flex: 2.5, textAlign: "center" }}>교육시작일</div>
    <div style={{ flex: 2.5, textAlign: "center" }}>교육종료일</div>
    <div style={{ flex: 1, textAlign: "center" }}>교육시간</div>
    <div style={{ flex: 2, textAlign: "center" }}>수료번호</div>
    <div style={{ flex: 2, textAlign: "center" }}>담당FT_1</div>
    <div style={{ flex: 2, textAlign: "center" }}>담당FT_2</div>
    <div style={{ flex: 1, textAlign: "center" }}>수료증 등록</div>
    <div style={{ flex: 1, textAlign: "center" }}>수정</div>
    <div style={{ flex: 1, textAlign: "center" }}>삭제</div>
  </div>
);

export default TableHeader;
