import React from "react";

const FileUploadModal = ({ showModal, setShowModal, handleFileUpload }) => {
  if (!showModal) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          width: "400px",
        }}
      >
        <h3>엑셀 파일 업로드</h3>
        <input
          type="file"
          accept=".csv, .xlsx, .xls"
          onChange={handleFileUpload}
        />
        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => setShowModal(false)}
            style={{
              padding: "10px 20px",
              backgroundColor: "#ff5252",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
