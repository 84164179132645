import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactLoading from "react-loading";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Wordcloud from "../components/Wordcloud";
import ReportCover from "../components/ReportCover";
import Loading from "../components/Loading";
import A4 from "../components/A4";
import axios from "axios";

const COLORS = [
  "#CC2B69",
  "#ED2B2B",
  "#FF812C",
  "#FFC842",
  "#DAE233",
  "#00C0E0",
  "#7CA1D3",
  "#B782B9",
];

const Container = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isLoading ? "100vh" : "auto")};
  margin-left: 280px;
`;
const Wrapper = styled.div`
  // width: 60%;
  display: flex;
  width: 793.7007874px;
  // padding: 0 0 28.1px;
  flex-direction: column;
  // padding: 40px;
  // border: 1px solid black;
`;

const Divider = styled.div`
  height: 0px;
  border-top: 1px solid #d9d9d9;
  margin-top: 15px;
  margin-bottom: 65px;
`;

const HeaderTopContainer = styled.div`
  width: 100%;
  height: 28px;
  margin: 0 0 55px;
  background-color: #ff812c;
`;
const HeaderTopBorder = styled.div`
  height: 10px;
  width: 12.5%;
  background-color: ${(props) => props.bgColor};
`;
const Header = styled.div`
  // width: 313px;
  // height: 89px;
  margin-bottom: 28px;
  font-family: PretendardVariable;
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #010101;
  position: relative;
`;

const HeaderBottomBar = styled.div`
  position: absolute;
  width: 228px;
  height: 19px;
  bottom: 0;
  background-color: #fda76b;
  opacity: 0.6;
  z-index: -1;
`;
const HeaderText = styled.span`
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin-left: 20px;
`;

const TagContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 0 52.9px;
  width: 100%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.div`
  width: 320px;
  // height: 42.3px;
  margin: 0 0 26px;
  padding: 15px 26px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  background-color: #ff812c;
  // border: 1px solid black;
`;

const SectionHeaderText = styled.span`
  // height: 22px;
  font-family: PretendardVariable;
  font-size: 22px;
  font-weight: 600;
  // font-stretch: normal;
  // font-style: normal;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #fff;
`;

const SectionBody = styled.div`
  display: flex;
  padding: 0 22px;
  margin-bottom: 60.7px;
`;

const SectionItem = styled.div`
  display: flex;
  // align-items: center;
  flex-direction: column;
  width: 400px;
  // border: 1px solid black;
`;

const SectionSubTitle = styled.p`
  // width: 88px;
  // height: 19px;
  width: 100%;
  margin: 0 260px 13px 0;
  font-family: PretendardVariable;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.64px;
  text-align: left;
  color: #000;
  // border: 1px solid black;
`;

const AllWordsBorder = styled.div`
  // width: 100%;
  height: 0;
  margin: 0 0 0 0.8px;
  opacity: 0.3;
  border-top: solid 1px #707070;
`;

const SectionAllWords = styled.div`
  display: flex;
  flex-wrap: wrap;
  // width: 302px;
  // height: 239px;
  margin: 12.7px 12.5px 0 0;
  font-family: PretendardVariable;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  // border: 1px solid black;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #010101;
  margin-bottom: 40px;
`;
const Row = styled.div`
  width: 100%;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 17px 26px;
  // margin: 10px 0;
  line-height: 22px;
  // padding-left: 16px;
`;

const RowHeader = styled.span`
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 23.5px;
`;
const RowFooter = styled.span`
  margin-left: auto;
  font-size: 21px;
  font-weight: 500;
  margin-top: 23.5px;
`;

const HashTagRow = styled.div`
  width: 100%;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 32px;
  margin: 10px 0;
  line-height: 22px;
`;

const HashTagWord = styled.span`
  font-size: 24px;
  color: #010101;
  line-height: 37px;
  letter-spacing: -0.5px;
  font-weight: 400;
`;

const HashTagFooter = styled.span`
  margin-left: auto;
  font-size: 26px;
  letter-spacing: -0.5px;
  line-height: 18px;
  font-weight: 500;
  margin-top: 30px;
`;

const WordContainer = styled.div`
  // width: 100%;
  display: inline;
  height: 16px;
  line-height: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  // border: 1px solid black;
`;
const Word = styled.span`
  // width: 24px;
  // height: 16px;

  margin-right: 5px;
  font-family: PretendardVariable;
  font-size: 18px;
  font-weight: ${({ count }) => (count >= 2 ? "600" : "300")};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.42px;
  text-align: left;
  color: ${({ count }) => (count >= 3 ? "#FF812C" : "#010101")};
  // border: 1px solid black;
`;

const WordCount = styled.span`
  font-size: 12px;
  line-height: 31px;
  letter-spacing: -0.2px;
  color: #ff812c;
  font-weight: 600;
`;

const GuideText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: -0.5px;
  color: #333333;
  position: absolute;
  bottom: 90px;
  right: 60px;
  text-align: right;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  // border: 1px solid black;
`;

const CommentHeader = styled.h3`
  font-weight: 500;
  font-size: 21px;
  line-height: 18px;

  letter-spacing: -0.5px;

  color: #010101;
`;

const CommentSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const CommentSectionList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  // border: 1px solid black;
`;

const CommentDivider = styled.div`
  width: 100%;
  height: 0;
  margin-top: 25px;
  margin-bottom: 16px;
  opacity: 0.5;
  border: solid 2px #a7a7a7;
`;

const CommentItemDivider = styled.div`
  width: 100%;
  height: 0;
  // margin: 0 4.9px 15.5px 0;
  margin: 15px 0;
  opacity: 0.5;
  border-top: solid 1px #d9d9d9;
`;

const CommentSectionItem = styled.p`
  width: 100%;
  margin: 0 0 15.5px 0;
  padding-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: PretendardVariable;
  font-size: 17px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #222222;
  // border: 1px solid black;
`;

const DownloadButton = styled.div`
  width: 60px;
  height: 60px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
`;

const comments = [
  "1번째입니다.1번째입니다.1번째입니다.1번째입니다.1번째입니다.1번째입니다.1번째입니다. 1번째입니다. 1번째입니다. 1번째입니다. 1번째입니다",
  // "2번째입니다.2번째입니다.2번째입니다.2번째입니다.2번째입니다.2번째입니다.2번째입니다. 2번째입니다. 2번째입니다. 2번째입니다. 2번째입니다",
  // "3번째입니다.3번째입니다.3번째입니다.3번째입니다.3번째입니다.3번째입니다.3번째입니다. 3번째입니다. 3번째입니다. 3번째입니다. 3번째입니다",
  // "4번째입니다.4번째입니다.4번째입니다.4번째입니다.4번째입니다.4번째입니다.4번째입니다. 4번째입니다. 4번째입니다. 4번째입니다. 4번째입니다",
  // "5번째입니다.5번째입니다.5번째입니다.5번째입니다.5번째입니다.5번째입니다.5번째입니다. 5번째입니다. 5번째입니다. 5번째입니다. 5번째입니다",
  // "6번째입니다.6번째입니다.6번째입니다.6번째입니다.6번째입니다.6번째입니다.6번째입니다. 6번째입니다. 6번째입니다. 6번째입니다. 6번째입니다",
  // "7번째입니다.7번째입니다.7번째입니다.7번째입니다.7번째입니다.7번째입니다.7번째입니다. 7번째입니다. 7번째입니다. 7번째입니다. 7번째입니다",
  // "8번째입니다.8번째입니다.8번째입니다.8번째입니다.8번째입니다.8번째입니다.8번째입니다. 8번째입니다. 8번째입니다. 8번째입니다. 8번째입니다",
  // "9번째입니다.9번째입니다.9번째입니다.9번째입니다.9번째입니다.9번째입니다.9번째입니다. 9번째입니다. 9번째입니다. 9번째입니다. 9번째입니다",
  // "10번째입니다.10번째입니다.10번째입니다.10번째입니다.10번째입니다.10번째입니다.10번째입니다. 10번째입니다. 10번째입니다. 10번째입",
  // "11번째입니다.",
  // "12번째입니다.",
  // "13번째입니다.",
  // "14번째입니다.",
  // "15번째입니다.",
];

// const appComments = [
//   "1번째입니다. - 감사",
//   "2번째입니다. - 감사",
//   "3번째입니다. - 감사",
//   "4번째입니다. - 감사",
//   "5번째입니다. - 감사",
//   "6번째입니다. - 감사",
//   "7번째입니다. - 감사",
//   "8번째입니다. - 감사",
//   "9번째입니다. - 감사",
//   "10번째입니다. - 감사",
//   "11번째입니다. - 감사",
//   "12번째입니다. - 감사",
//   "13번째입니다. - 감사",
//   "1번째입니다. - 감사",
//   "2번째입니다. - 감사",
//   "3번째입니다. - 감사",
//   "4번째입니다. - 감사",
//   "5번째입니다. - 감사",
//   "6번째입니다. - 감사",
//   "7번째입니다. - 감사",
//   "8번째입니다. - 감사",
//   "9번째입니다. - 감사",
//   "10번째입니다. - 감사",
//   "11번째입니다. - 감사",
//   "12번째입니다. - 감사",
//   "13번째입니다. - 감사",
// ];

function Report() {
  const [reportData, setReportData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState("리포트 생성중...");
  const [pages, setPages] = useState(1);
  const [expectPages, setExpectPages] = useState();

  const [appreciateCurrentIndex, setAppreciateCurrentIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [appreciateOffsets, setAppreciateOffsets] = useState([0]);
  const [offsets, setOffsets] = useState([0]);
  const [appreciateEnd, setAppreciateEnd] = useState(false);
  // const [appreciateOverflow, setAppreciateOverflow] = useState()
  const [appreciatePage, setAppreciatePage] = useState(1);
  const expectHeight = useRef();

  const [appComments, setAppComments] = useState([]);

  const printRef = useRef();

  const appreciateRef = useRef([]);
  const commentRef = useRef([]);
  const counterRef = useRef(0);

  const location = useLocation();
  // location.state.name = "김미나";

  useEffect(() => {
    console.log("++++++++++++++");
    console.log(location.state);
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/betterthan?name=${location.state.name}&pid=${location.state.pid}`
      );
      console.log(response);
      setReportData([
        response.data.hashtags,
        response.data.valuesWords,
        response.data.feelingWords,
        response.data.thinkingWords,
        response.data.doingWords,
        response.data.appreciateComments,
        response.data.expectComments,
      ]);
      setCount(response.data.count);
      setIsLoading(false);
    };
    if (counterRef.current === 0) {
      // setReportData([
      //   location.state.data.hashtags,
      //   location.state.data.valuesWords,
      //   location.state.data.feelingWords,
      //   location.state.data.thinkingWords,
      //   location.state.data.doingWords,
      //   location.state.data.appreciateComments,
      //   location.state.data.expectComments,
      // ]);

      fetchData();

      counterRef.current += 1;
    }
  }, []);

  const handleDownloadPdf = async () => {
    setLoadingMsg("pdf 다운로드중...");
    setIsLoading(true);
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      scale: 3,
    });

    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    const pageHeight = 297;
    let heightLeft = pdfHeight;

    let position = 0;

    pdf.addImage(
      data,
      "PNG",
      0,
      position,
      pdfWidth,
      pdfHeight,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - pdfHeight;
      pdf.addPage();
      pdf.addImage(
        data,
        "PNG",
        0,
        position,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;
    }
    pdf.save(`BetterThanFeedback-${location.state.name}.pdf`);
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(reportData);
    // console.log(
    //   pages,
    //   currentIndex,
    //   commentRef.current.length,
    //   offsets,
    //   offsets.includes(currentIndex)
    // );
    // if (counterRef.current === 1) {

    let isLast = 0;
    let isPageOverflow = false;
    let expectStart = false;
    setTimeout(() => {
      let height = 0;
      console.log(
        "Start: ",
        appreciateCurrentIndex,
        appreciateRef.current.length,
        reportData[5]
      );
      // 감사 피드백 페이징
      if (!appreciateEnd) {
        for (
          let i = appreciateCurrentIndex;
          i < appreciateRef.current.length;
          i++
        ) {
          height += appreciateRef.current[i].getBoundingClientRect().height;
          if (height > 720) {
            console.log(
              "!!",
              i,
              height,
              appreciateRef.current.length,
              comments.length
            );
            setAppreciateCurrentIndex(i);
            // setPages((prev) => prev + 1);
            // alert(`Overflowed 600 at ${i}th element`);
            if (!appreciateOffsets.includes(i)) {
              setAppreciateOffsets((prev) => [...prev, i]);
              isPageOverflow = true;
              setAppreciatePage(appreciatePage + 1);
              height = 0;
            }
            isLast += 1;
            break;
          } else {
            console.log(i, comments.length - 1);
            if (i + 1 >= reportData[5]?.length) {
              setAppreciateEnd(true);
              expectStart = true;
              setOffsets(new Array(pages).fill(0));
              console.log("last hiehgt:", height);
              expectHeight.current = height;
              break;
            }
            console.log(
              "!!",
              i,
              height,
              appreciateRef.current.length,
              comments.length
            );
            // isLastPage = true;
          }
        }
      }

      setIsLastPage(isLast > 0);
      if (isPageOverflow) {
        setPages((prev) => prev + 1);
      } else {
        console.log("no overflow!");
      }

      // console.log("is last page =>", isLastPage, pages);
    }, 500);
  }, [pages, reportData]);

  // useEffect(() => {
  //   if (appreciateEnd) {
  //     console.log("show comments!", pages);
  //     let isLast = 0;
  //     let isPageOverflow = false;

  //     setTimeout(() => {
  //       let height = 300;
  //       // commentRef.current.filter((i) => i)[0].getBoundingClientRect().y -
  //       // 1122 * (pages + 3) +
  //       // commentRef.current.filter((i) => i)[0].getBoundingClientRect().height;

  //       // 기대 피드백 페이징

  //       console.log(
  //         "how much is my height?",
  //         height,
  //         offsets,
  //         commentRef.current,
  //         commentRef.current.filter((i) => i)[0],
  //         commentRef.current.filter((i) => i)[0].getBoundingClientRect(),
  //         commentRef.current.filter((i) => i)[0].getBoundingClientRect().y -
  //           1122 * (pages + 3) +
  //           commentRef.current.filter((i) => i)[0].getBoundingClientRect()
  //             .height
  //       );
  //       for (let i = currentIndex; i < commentRef.current.length; i++) {
  //         height += commentRef.current[i].getBoundingClientRect().height;
  //         console.log(
  //           "expect paging: ",
  //           height,
  //           currentIndex,
  //           commentRef.current.length
  //         );
  //         if (height > 800) {
  //           console.log("??", i);
  //           setCurrentIndex(i);
  //           // setPages((prev) => prev + 1);
  //           // alert(`Overflowed 600 at ${i}th element`);
  //           if (!offsets.includes(i)) {
  //             console.log("hi", i, offsets);
  //             setOffsets((prev) => [...prev, i]);
  //             // setPages((prev) => prev + 1);
  //             isPageOverflow = true;
  //           }
  //           isLast += 1;
  //           break;
  //         } else {
  //           // isLastPage = true;
  //         }
  //       }

  //       setIsLastPage(isLast > 0);
  //       if (isPageOverflow) {
  //         console.log("overflowed!");
  //         setPages((prev) => prev + 1);
  //       }

  //       // console.log("is last page =>", isLastPage, pages);
  //     }, 500);
  //   }
  // }, [appreciateEnd, pages]);

  useEffect(() => {
    // setAppComments([
    // "1번째입니다. - 감사",
    // "2번째입니다. - 감사",
    // // "3번째입니다. - 감사",
    // // "4번째입니다. - 감사",
    // // "5번째입니다. - 감사",
    // // "6번째입니다. - 감사",
    // // "7번째입니다. - 감사",
    // // "8번째입니다. - 감사",
    // // "9번째입니다. - 감사",
    // // "10번째입니다. - 감사",
    // // "11번째입니다. - 감사",
    // // "12번째입니다. - 감사",
    // // "13번째입니다. - 감사",
    // // "1번째입니다. - 감사",
    // // "2번째입니다. - 감사",
    // // "3번째입니다. - 감사",
    // // "4번째입니다. - 감사",
    // // "5번째입니다. - 감사",
    // // "6번째입니다. - 감사",
    // // "7번째입니다. - 감사",
    // // "8번째입니다. - 감사",
    // // "9번째입니다. - 감사",
    // // "10번째입니다. - 감사",
    // // "11번째입니다. - 감사",
    // // "12번째입니다. - 감사",
    // // "13번째입니다. - 감사",
    // // "9번째입니다. - 감사",
    // // "10번째입니다. - 감사",
    // // "11번째입니다. - 감사",
    // // "12번째입니다. - 감사",
    // // "13번째입니다. - 감사",
    // ]);
    console.log("TTTTTTTTT", commentRef.current[0]);
    if (appreciateEnd) {
      let height = expectHeight.current;
      let isPageOverflow = false;
      for (let i = currentIndex; i < commentRef.current.length; i++) {
        height += commentRef.current[i].getBoundingClientRect().height;
        console.log(height);
        if (height > 800) {
          console.log(
            "!!",
            i,
            height,
            commentRef.current.length,
            appComments.length
          );
          setCurrentIndex(i);
          // setPages((prev) => prev + 1);
          // alert(`Overflowed 600 at ${i}th element`);
          // if (!offsets.includes(i)) {
          console.log("^%%%%%%%%%%%%%%%%");
          setOffsets((prev) => [...prev, i]);
          // setPages((prev) => prev + 1);
          isPageOverflow = true;
          expectHeight.current = 0;
          // } else {
          //   console.log("@@@@@@@@@");
          // }
          break;
        }
      }
      expectHeight.current = 0;
      if (isPageOverflow) {
        console.log("overflowed!");
        setPages((prev) => prev + 1);
      }
    }
  }, [appreciateEnd, pages]);
  console.log("appreciateCurrentIndex =>", appreciateCurrentIndex);
  console.log("expectCurrentIndex =>", currentIndex);
  console.log("appreciate offset =>", appreciateOffsets);
  console.log("offset => ", offsets);
  console.log("isLastpage => ", isLastPage);
  console.log("appreciateRef =>", appreciateRef.current);
  console.log("commentRef =>", commentRef.current);
  console.log(
    appreciateOffsets[1],
    1 + 1 < comments.length,
    appreciateOffsets[1 + 1],
    comments.length
  );
  console.log(
    offsets[pages],
    pages + 1 < appComments.length,
    offsets[pages + 1],
    appComments.length
  );
  console.log(
    comments.slice(
      appreciateOffsets[pages],
      pages + 1 < comments.length
        ? appreciateOffsets[pages + 1]
        : comments.length
    )
  );
  console.log(
    appComments.slice(
      offsets[pages],
      pages + 1 < appComments.length ? offsets[pages + 1] : appComments.length
    )
  );
  if (isLoading) {
    return null;
  }
  console.log(location.state);
  console.log(reportData);
  return (
    <Container isLoading={isLoading}>
      {isLoading ? (
        <Loading
          message={loadingMsg}
          animate={loadingMsg !== "pdf 다운로드중..."}
        />
      ) : (
        <>
          <Wrapper ref={printRef}>
            <ReportCover
              name={location.state.name}
              title={location.state.pid}
              project={location.state.projectName}
            />
            {/* <HeaderTopContainer>
              {COLORS.map((color, index) => (
                <HeaderTopBorder key={index} bgColor={color} />
              ))}
            </HeaderTopContainer> */}
            <A4 pageNum={2} name={location.state.name}>
              <Header>
                함께 일하는 동료 {count}인이
                <br />
                피드백하기를 {location.state.name} 님은
                {/* <HeaderBottomBar /> */}
              </Header>

              <SectionContainer>
                <RowContainer>
                  <HashTagRow>
                    {reportData[0].map((word, index) => {
                      // <WordContainer key={index}>

                      return (
                        <HashTagWord key={index} count={word[1]}>
                          {`${word[0]}${
                            index === reportData[0].length - 1 ? "" : ","
                          }`}
                        </HashTagWord>
                      );
                      // {/* <WordCount>{word[1]}</WordCount> */}
                      // </WordContainer>
                    })}
                  </HashTagRow>
                  <HashTagFooter>사람입니다.</HashTagFooter>
                </RowContainer>
                <Divider />
                <TagContainer>
                  <SectionItem>
                    <SectionHeader>
                      <SectionHeaderText>강점 태그 클라우드</SectionHeaderText>
                    </SectionHeader>
                    {/* <SectionSubTitle>TOP 3</SectionSubTitle> */}
                    <Wordcloud
                      data={[
                        ...reportData[2],
                        ...reportData[3],
                        ...reportData[4],
                      ]}
                      count={count}
                      type="strong"
                    />
                  </SectionItem>
                  <SectionItem style={{ alignItems: "flex-end" }}>
                    <SectionHeader>
                      <SectionHeaderText>가치 태그 클라우드</SectionHeaderText>
                    </SectionHeader>
                    <Wordcloud
                      data={reportData[1]}
                      count={count}
                      type="value"
                    />
                  </SectionItem>
                </TagContainer>

                {/* <Section>
                  <SectionHeader>
                    <SectionHeaderText>
                      동료들이 바라본{" "}
                      <b style={{ fontWeight: "bold" }}>나의 가치</b>
                    </SectionHeaderText>
                  </SectionHeader>
                  <SectionBody>
                    <SectionItem>
                      <SectionSubTitle>TOP 3</SectionSubTitle>
                      <Wordcloud data={reportData[1]} type="value" />
                    </SectionItem>
                    <SectionItem>
                      <SectionSubTitle>가치 단어 리스트</SectionSubTitle>
                      <AllWordsBorder />
                      <SectionAllWords>
                        {reportData[1].map((word, index) => (
                          <WordContainer key={index}>
                            <Word
                              style={{
                                fontWeight: word[1] >= 2 ? "bold" : "normal",
                              }}
                            >
                              {word[0]}
                            </Word>{" "}
                            <WordCount
                              style={{
                                fontWeight: word[1] >= 2 ? "bold" : "normal",
                              }}
                            >
                              {word[1]}
                            </WordCount>
                          </WordContainer>
                        ))}
                      </SectionAllWords>
                    </SectionItem>
                  </SectionBody>
                </Section> */}
                {/* <Section>
                  <SectionHeader>
                    <SectionHeaderText>
                      동료들이 바라본{" "}
                      <b style={{ fontWeight: "bold" }}>나의 강점</b>
                    </SectionHeaderText>
                  </SectionHeader>
                  <SectionBody>
                    <SectionItem>
                      <SectionSubTitle>TOP 3</SectionSubTitle>
                      <Wordcloud data={reportData[0]} type="strength" />
                    </SectionItem>
                    <SectionItem>
                      <SectionSubTitle>강점 단어 리스트</SectionSubTitle>
                      <AllWordsBorder />
                      <SectionAllWords>
                        {reportData[0].map((word, index) => (
                          <WordContainer key={index}>
                            <Word>{word[0]}</Word>{" "}
                            <WordCount>{word[1]}</WordCount>
                          </WordContainer>
                        ))}
                      </SectionAllWords>
                    </SectionItem>
                  </SectionBody>
                </Section> */}
              </SectionContainer>
              {/* <GuideText>
                * 2명 이상 동일한 응답은 굵은글씨, 3명 이상 동일한 응답은
                주황색으로 표시됩니다.
              </GuideText> */}
            </A4>
            <A4 pageNum={3} name={location.state.name}>
              {/* <Header>
                {location.state.name}{" "}
                <b style={{ fontWeight: "normal" }}>님을 위한</b>
                <br />
                긍정 피드백 결과<b style={{ fontWeight: "normal" }}>입니다.</b>
                <HeaderBottomBar />
              </Header> */}

              <SectionContainer>
                <RowContainer>
                  <RowHeader>
                    <span style={{ fontWeight: "bold" }}>
                      {location.state.name} 님
                    </span>
                    은 감성적인 면에서
                  </RowHeader>
                  <Row>
                    {reportData[2].map((word, index) => {
                      // <WordContainer key={index}>
                      return (
                        <Word key={index} count={word[1]}>
                          {word[1] > 2 ? (
                            <>
                              {word[0]}
                              <WordCount>({word[1]})</WordCount>
                              {index === reportData[2].length - 1 ? "" : ","}
                            </>
                          ) : (
                            <>
                              {word[0]}
                              {index === reportData[2].length - 1 ? "" : ","}
                            </>
                          )}
                        </Word>
                      );
                      // {/* <WordCount>{word[1]}</WordCount> */}
                      // </WordContainer>
                    })}
                  </Row>
                  <RowFooter>강점이 보입니다.</RowFooter>
                </RowContainer>
                <RowContainer>
                  <RowHeader>
                    <span style={{ fontWeight: "bold" }}>
                      {location.state.name} 님
                    </span>
                    은 인지적인 면에서
                  </RowHeader>
                  <Row>
                    {reportData[3].map((word, index) => {
                      // <WordContainer key={index}>
                      return (
                        <Word key={index} count={word[1]}>
                          {word[1] > 2 ? (
                            <>
                              {word[0]}
                              <WordCount>({word[1]})</WordCount>
                              {index === reportData[3].length - 1 ? "" : ","}
                            </>
                          ) : (
                            <>
                              {word[0]}
                              {index === reportData[3].length - 1 ? "" : ","}
                            </>
                          )}
                        </Word>
                      );
                      // {/* <WordCount>{word[1]}</WordCount> */}
                      // </WordContainer>
                    })}
                  </Row>
                  <RowFooter>강점이 보입니다.</RowFooter>
                </RowContainer>
                <RowContainer>
                  <RowHeader>
                    <span style={{ fontWeight: "bold" }}>
                      {location.state.name} 님
                    </span>
                    은 행동적인 면에서
                  </RowHeader>
                  <Row>
                    {reportData[4].map((word, index) => {
                      // <WordContainer key={index}>
                      return (
                        <Word key={index} count={word[1]}>
                          {word[1] > 2 ? (
                            <>
                              {word[0]}
                              <WordCount>({word[1]})</WordCount>
                              {index === reportData[4].length - 1 ? "" : ","}
                            </>
                          ) : (
                            <>
                              {word[0]}
                              {index === reportData[4].length - 1 ? "" : ","}
                            </>
                          )}
                        </Word>
                      );
                      // {/* <WordCount>{word[1]}</WordCount> */}
                      // </WordContainer>
                    })}
                  </Row>
                  <RowFooter>강점이 보입니다.</RowFooter>
                </RowContainer>
                <RowContainer>
                  <RowHeader>
                    <span style={{ fontWeight: "bold" }}>
                      {location.state.name} 님
                    </span>
                    은 일하면서
                  </RowHeader>
                  <Row>
                    {reportData[1].map((word, index) => {
                      // <WordContainer key={index}>
                      return (
                        <Word key={index} count={word[1]}>
                          {word[1] > 2 ? (
                            <>
                              {word[0]}
                              <WordCount>({word[1]})</WordCount>
                              {index === reportData[1].length - 1 ? "" : ","}
                            </>
                          ) : (
                            <>
                              {word[0]}
                              {index === reportData[1].length - 1 ? "" : ","}
                            </>
                          )}
                        </Word>
                      );
                      // {/* <WordCount>{word[1]}</WordCount> */}
                      // </WordContainer>
                    })}
                  </Row>
                  <RowFooter>을 중요하게 생각합니다.</RowFooter>
                </RowContainer>
                {/* <Section>
                  <SectionHeader>
                    <SectionHeaderText>
                      동료들이 바라본{" "}
                      <b style={{ fontWeight: "bold" }}>나의 강점</b>
                    </SectionHeaderText>
                  </SectionHeader>
                  <SectionBody>
                    <SectionItem>
                      <SectionSubTitle>TOP 3</SectionSubTitle>
                      <Wordcloud data={reportData[1]} type="value" />
                    </SectionItem>
                    <SectionItem>
                      <SectionSubTitle>강점 단어 리스트</SectionSubTitle>
                      <AllWordsBorder />
                      <SectionAllWords>
                        {reportData[0].map((word, index) => (
                          <WordContainer key={index}>
                            <Word
                              style={{
                                fontWeight: word[1] >= 2 ? "bold" : "normal",
                              }}
                            >
                              {word[0]}
                            </Word>{" "}
                            <WordCount
                              style={{
                                fontWeight: word[1] >= 2 ? "bold" : "normal",
                              }}
                            >
                              {word[1]}
                            </WordCount>
                          </WordContainer>
                        ))}
                      </SectionAllWords>
                    </SectionItem>
                  </SectionBody>
                </Section> */}
                {/* <Section>
                  <SectionHeader>
                    <SectionHeaderText>
                      동료들이 바라본{" "}
                      <b style={{ fontWeight: "bold" }}>나의 가치</b>
                    </SectionHeaderText>
                  </SectionHeader>
                  <SectionBody>
                    <SectionItem>
                      <SectionSubTitle>TOP 3</SectionSubTitle>
                      <Wordcloud data={reportData[0]} type="strength" />
                    </SectionItem>
                    <SectionItem>
                      <SectionSubTitle>강점 단어 모음</SectionSubTitle>
                      <AllWordsBorder />
                      <SectionAllWords>
                        {reportData[0].map((word, index) => (
                          <WordContainer key={index}>
                            <Word>{word[0]}</Word>{" "}
                            <WordCount>{word[1]}</WordCount>
                          </WordContainer>
                        ))}
                      </SectionAllWords>
                    </SectionItem>
                  </SectionBody>
                </Section> */}
              </SectionContainer>
              <GuideText>
                * 2명 이상 동일한 응답은 굵은글씨, 3명 이상 동일한 응답은
                주황색으로 표시됩니다.
              </GuideText>
            </A4>
            {Array.from({ length: pages }, (_, i) => i + 1).map(
              (item, page) => {
                console.log("page:", pages, item);
                return (
                  <A4
                    pageNum={4 + page}
                    type="comment"
                    name={location.state.name}
                    key={page}
                  >
                    {/* <Header>
                  {location.state.name}{" "}
                  <b style={{ fontWeight: "normal" }}>님을 위한</b>
                  <br />
                  긍정 피드백 결과
                  <b style={{ fontWeight: "normal" }}>입니다.</b>
                  <HeaderBottomBar />
                </Header> */}
                    <CommentContainer>
                      <CommentSection>
                        {/* <SectionHeader style={{ width: "331px" }}> */}
                        {page === 0 && (
                          <>
                            <CommentHeader>
                              동료들이 전하는 고마운 마음입니다 😊
                            </CommentHeader>
                            <CommentDivider />
                          </>
                        )}
                        {/* </SectionHeader> */}
                        <CommentSectionList>
                          {/* {console.log(
                            "rendering thanks",
                            page,
                            appreciateOffsets[page],
                            appreciateOffsets[page + 1]
                          )} */}
                          {reportData[5]
                            .slice(
                              appreciateOffsets[page],
                              page + 1 < reportData[5].length
                                ? appreciateOffsets[page + 1]
                                : reportData[5].length
                            )
                            .map((comment, index) => {
                              if (
                                reportData[5][appreciateOffsets[page] + index]
                              ) {
                                return (
                                  <div
                                    key={index}
                                    ref={(el) => {
                                      // console.log(
                                      //   el,
                                      //   appreciateCurrentIndex,
                                      //   index
                                      // );
                                      return (appreciateRef.current[
                                        appreciateCurrentIndex + index
                                      ] = el);
                                    }}
                                  >
                                    <CommentSectionItem>
                                      {
                                        reportData[5][
                                          appreciateOffsets[page] + index
                                        ]
                                      }
                                    </CommentSectionItem>
                                    <CommentItemDivider />
                                  </div>
                                );
                              }
                            })}
                          {/* {reportData[2]
                        .slice(
                          isLastPage ? offsets[i] : appreciateCurrentIndex,
                          i + 1 < reportData[2].length
                            ? offsets[i + 1]
                            : reportData[2].length
                        )
                        .map((comment, index) => (
                          <div
                            key={index}
                            ref={(el) =>
                              (appreciateRef.current[
                                appreciateCurrentIndex + index
                              ] = el)
                            }
                          >
                            <CommentSectionItem>{comment}</CommentSectionItem>
                            <CommentDivider />
                          </div>
                        ))} */}
                        </CommentSectionList>
                      </CommentSection>

                      {appreciateEnd && appreciatePage <= item && (
                        <CommentSection>
                          {/* <SectionHeader style={{ width: "331px" }}> */}
                          {appreciatePage === item && (
                            <>
                              <CommentHeader>
                                동료들이 기대하는 마음입니다 🙏
                              </CommentHeader>
                              <CommentDivider />
                            </>
                          )}
                          {/* </SectionHeader> */}
                          <CommentSectionList>
                            {/* {console.log(
                              "rendering",
                              page,
                              offsets[page],
                              offsets[page + 1]
                            )} */}
                            {reportData[6]
                              .slice(
                                offsets[page],
                                page + 1 < reportData[6].length
                                  ? offsets[page + 1]
                                  : reportData[6].length
                              )
                              .map((comment, index) => {
                                // console.log(
                                //   "who first?",
                                //   comment,
                                //   offsets[page] + index,
                                //   commentRef.current
                                // );
                                if (reportData[6][offsets[page] + index]) {
                                  // console.log("ahislaj");
                                  // console.log(
                                  //   commentRef.current.length,
                                  //   offsets[page] + index,
                                  //   appComments[offsets[page] + index]
                                  // );
                                  return (
                                    <div
                                      key={index}
                                      ref={(el) => {
                                        // console.log(el, currentIndex, index);
                                        return (commentRef.current[
                                          currentIndex + index
                                        ] = el);
                                      }}
                                    >
                                      <CommentSectionItem>
                                        {reportData[6][offsets[page] + index]}
                                        {/* {comment} */}
                                      </CommentSectionItem>
                                      <CommentItemDivider />
                                    </div>
                                  );
                                } else {
                                  console.log("&&&&&&&&&&&&&&&&&&&&&&&&&");
                                }
                              })}
                            {/* {reportData[3].map((comment, index) => (
                      <div key={index}>
                        <CommentSectionItem>{comment}</CommentSectionItem>
                        <CommentDivider />
                      </div>
                    ))} */}
                          </CommentSectionList>
                        </CommentSection>
                      )}
                    </CommentContainer>
                  </A4>
                );
              }
            )}
          </Wrapper>
          <DownloadButton onClick={handleDownloadPdf}>
            <span>PDF</span>
            <span>다운로드</span>
          </DownloadButton>
        </>
      )}
    </Container>
  );
}

export default Report;
