import styled from "styled-components";

const Contaienr = styled.div`
  display: flex;
  flex-direction: column;
  width: 793.7007874px;
  height: 1122.519685px;
  position: relative;
  // border: 1px solid black;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 53px;
`;

const HeaderTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0 55px;
  // border: 1px solid black;
`;

const HeaderTop = styled.div`
  width: 90%;
  height: 4px;
  background-color: #ff812c;
`;

const BackgroundImage = styled.img`
  width: 421.1px;
  height: 255.1px;
  margin: 83.8px 52.4px 33.5px 320.2px;
  object-fit: contain;
  opacity: 1;
  position: absolute;
  bottom: 35px;
`;

const Footer = styled.div`
  display: flex;
  width: 688.3px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 10px;
  border-top: solid 2px #d9d9d9;
  // border: 1px solid black;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FooterLeft = styled.div`
  display: flex;
  // border: 1px solid black;
`;

const FooterLogo = styled.img`
  width: 64.1px;
  // height: 19.7px;
  // margin: 17px 374px 0 0;
  object-fit: contain;
  // border: 1px solid black;
`;

const FooterSeparator = styled.div`
  width: 1px;
  // height: 20px;
  background-color: #d9d9d9;
  margin-left: 10px;
  margin-right: 10px;
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  // line-height: 12px;
  // letter-spacing: -0.03em;
  color: #666666;
  // font-weight: 300;
`;
const FooterText = styled.p`
  // font-family: PretendardVariable;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.5px;
  margin-bottom: 2px;
`;
const FooterCopyRight = styled.p`
  font-size: 7px;
  font-weight: 300;
`;

const FooterPageNum = styled.p`
  font-family: PretendardVariable;
  font-size: 8px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 10px;

  color: #666666;
`;

function A4({ children, pageNum, type, name }) {
  return (
    <Contaienr>
      <HeaderTopContainer>
        <HeaderTop></HeaderTop>
      </HeaderTopContainer>
      <Wrapper>{children}</Wrapper>

      {/* {type === "comment" && (
        <BackgroundImage
          src={`${process.env.PUBLIC_URL}/assets/positive-feedback-report-image3x.png`}
        />
      )} */}
      <Footer>
        {/* <FooterBorder /> */}
        <FooterLeft>
          <FooterLogo
            src={`${process.env.PUBLIC_URL}/assets/positive-feedback-footer-logo3x.png`}
          />
          <FooterSeparator />
          <FooterTextContainer>
            <FooterText>
              <span style={{ fontWeight: 400 }}>베러댄피드백™</span>
            </FooterText>
            <FooterCopyRight>
              Copyright &copy; {new Date().getFullYear()} BLOOM COMPANY. All
              rights reserved.
            </FooterCopyRight>
          </FooterTextContainer>
        </FooterLeft>
        <FooterPageNum>{pageNum}</FooterPageNum>
      </Footer>
    </Contaienr>
  );
}

export default A4;
