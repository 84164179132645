import { useTable } from "react-table";
import styled from "styled-components";

const ProjectTable = styled.table`
  width: 100%;
  // border: 1px soild black;
`;

const ProjectHeader = styled.thead`
  width: 100%;
  font-weight: 700;
  border-bottom: 1px solid black;
`;

const ProjectHeaderColumn = styled.th`
  padding-bottom: 10px;
`;

const ProjectRow = styled.tr``;

const ProjectRowColumn = styled.td`
  flex: ${({ flex }) => flex || 1};
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
`;

const Table = ({
  columns,
  showCheckBox,
  data,
  handler,
  reportHandler,
  onChange,
}) => {
  return (
    <ProjectTable>
      <ProjectHeader>
        <ProjectRow>
          {columns.map((column, index) => (
            <ProjectHeaderColumn key={index}>
              {column.Header}
            </ProjectHeaderColumn>
          ))}
        </ProjectRow>
      </ProjectHeader>
      <tbody>
        {data.map((item, index) => (
          <ProjectRow key={index}>
            {showCheckBox && (
              <ProjectRowColumn>
                <input
                  id={index}
                  type="checkbox"
                  name="remind"
                  defaultChecked={true}
                  onChange={onChange}
                />
              </ProjectRowColumn>
            )}
            <ProjectRowColumn>{item.name}</ProjectRowColumn>
            <ProjectRowColumn>{item.email}</ProjectRowColumn>
            <ProjectRowColumn>{item.department}</ProjectRowColumn>
            <ProjectRowColumn>{item.birthday}</ProjectRowColumn>
            <ProjectRowColumn>{item.group}</ProjectRowColumn>
            {/* <ProjectRowColumn>
              {new Date(item.sendDate).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {new Date(item.surveyEndDate).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {new Date(item.firstRemindDate).toLocaleDateString()}
            </ProjectRowColumn>
            <ProjectRowColumn>
              {new Date(item.secondRemindDate).toLocaleDateString()}
            </ProjectRowColumn> */}
            <ProjectRowColumn>{item.friends.length}</ProjectRowColumn>
            <ProjectRowColumn>
              {item.friends.filter((f) => f.reply).length}
            </ProjectRowColumn>
            <ProjectRowColumn>
              <button onClick={() => handler(index)}>보기</button>
            </ProjectRowColumn>
            <ProjectRowColumn>
              <button onClick={() => reportHandler(index)}>
                리포트 다운로드
              </button>
            </ProjectRowColumn>
          </ProjectRow>
        ))}
        {/* {rows.map((row, index) => {
          const items = Object.keys(row);
          return (
            // getRowProps는 각 row data를 호출해낸다
            <ProjectRow key={index}>
              {items.map((item, i) => (
                <ProjectRowColumn key={i}>{row[item]}</ProjectRowColumn>
              ))}
            </ProjectRow>
          );
        })} */}
      </tbody>
    </ProjectTable>
  );
};

export default Table;
